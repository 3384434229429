<template>
  <div>
    <div v-if="fullNavShown != 'large'" class="uk-flex uk-flex-around">
      <LogOut class="uk-margin-top" />
    </div>
    <div v-if="fullNavShown === 'large'" class="uk-flex uk-flex-between">
      <LogOut class="uk-margin-left uk-margin-top" large />
      <div
        class="language-switcher uk-flex uk-flex-right uk-margin-right uk-margin-top"
      >
        <a
          :class="{
            'language-active': locale == 'cs',
            'language-inactive': locale != 'cs',
          }"
          @click="setLanguage('cs')"
          >CS</a
        >
        <span> | </span>
        <a
          :class="{
            'language-active': locale == 'en',
            'language-inactive': locale != 'en',
          }"
          @click="setLanguage('en')"
          >EN</a
        >
      </div>
    </div>
    <div class="left-logo uk-flex uk-flex-middle">
      <router-link :to="{ name: 'products' }">
        <transition name="fade">
          <img
            v-if="fullNavShown === 'large'"
            id="logo-bg"
            src="../../assets/images/azor_large_logo_black.png"
          />
          <img
            v-else
            id="logo-sm"
            src="../../assets/images/azor_small_black.png"
          />
        </transition>
      </router-link>
    </div>
    <div
      v-if="fullNavShown === 'large'"
      class="uk-margin-small-right uk-cursor-pointer big-nav-toggle"
      :uk-tooltip="$t('navigation.shrink_menu')"
      uk-icon="icon: chevron-left"
      @click="toggleMenu()"
    />
    <div
      class="left-nav-wrap"
      :class="fullNavShown === 'large' ? 'left-nav-wrap-large' : ''"
    >
      <ul class="uk-nav uk-nav-default" data-uk-nav>
        <li v-if="fullNavShown === 'small'">
          <span
            uk-icon="icon: chevron-right"
            class="uk-margin-small-right uk-cursor-pointer small-nav-toggle"
            :uk-tooltip="$t('navigation.expand_menu')"
            @click="toggleMenu()"
          />
        </li>
        <li
          v-if="
            admin ||
            permissions.includes('search') ||
            permissions.includes('support')
          "
        >
          <router-link :to="{ name: 'products' }">
            <span
              data-uk-icon="icon: tag"
              class="uk-margin-small-right"
              :uk-tooltip="$t('navigation.products')"
            />
            <span class="hideOnSmallMenu">
              {{ $t('navigation.products') }}
            </span>
          </router-link>
        </li>
        <li v-if="admin">
          <router-link :to="{ name: 'rules' }">
            <span
              data-uk-icon="icon: album"
              class="uk-margin-small-right"
              :uk-tooltip="$t('navigation.rules')"
            />
            <span class="hideOnSmallMenu">
              {{ $t('navigation.rules') }}
            </span>
          </router-link>
        </li>
        <li
          v-if="
            admin ||
            permissions.includes('files') ||
            permissions.includes('search')
          "
          class="uk-flex"
        >
          <router-link :to="{ name: 'files' }">
            <span
              data-uk-icon="icon: folder"
              class="uk-margin-small-right"
              :uk-tooltip="$t('navigation.files')"
            />
            <span class="hideOnSmallMenu">
              {{ $t('navigation.files') }}
            </span>
          </router-link>
        </li>
        <li v-if="admin || permissions.includes('users')" class="uk-flex">
          <router-link :to="{ name: 'users' }">
            <span
              data-uk-icon="icon: users"
              class="uk-margin-small-right"
              :uk-tooltip="$t('navigation.users')"
            />
            <span class="hideOnSmallMenu">
              {{ $t('navigation.users') }}
            </span>
          </router-link>
        </li>
        <li
          v-if="
            admin ||
            permissions.includes('support') ||
            permissions.includes('search')
          "
        >
          <router-link :to="{ name: 'approval-system' }">
            <span
              data-uk-icon="icon: check"
              class="uk-margin-small-right"
              :uk-tooltip="$t('navigation.approval_system')"
            />
            <span class="hideOnSmallMenu">
              {{ $t('navigation.approval_system') }}
            </span>
          </router-link>
        </li>
        <li v-if="admin || permissions.includes('pairing')">
          <router-link :to="{ name: 'user-pairings' }">
            <span
              data-uk-icon="icon: social"
              class="uk-margin-small-right"
              :uk-tooltip="$t('navigation.user_pairings')"
            />
            <span class="hideOnSmallMenu">
              {{ $t('navigation.user_pairings') }}
            </span>
          </router-link>
        </li>
      </ul>
      <ul class="uk-nav uk-nav-default uk-margin-bottom nav-bottom" data-uk-nav>
        <li
          v-if="availableClients.length > 1"
          class="hideOnSmallMenu uk-nav-li-instance-picker uk-padding-remove-top uk-padding-remove-bottom"
        >
          <span
            uk-grid
            class="hideOnSmallMenu uk-flex uk-flex-between uk-margin-small-bottom uk-flex-middle"
          >
            <span class="uk-text-small">INSTANCE</span>
            <router-link
              :to="{
                name: 'edit-instance',
                params: { instanceName: currentSession },
              }"
              :uk-tooltip="`title: ${$t(
                'navigation.edit_current_instance'
              )}; pos: top-left`"
            >
              <span uk-icon="icon: settings" />
            </router-link>
          </span>
          <InstancePicker />
        </li>
        <li class="uk-position-relative uk-cursor-pointer padding-top">
          <Tools v-if="admin" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InstancePicker from '@/components/InstancePicker'
import Tools from './Tools.vue'
import LogOut from '@/components/LogOut.vue'

export default {
  components: {
    InstancePicker,
    Tools,
    LogOut,
  },
  data() {
    return {
      fullNavShown: '',
    }
  },
  computed: {
    ...mapState('users', [
      'permissions',
      'admin',
      'availableClients',
      'currentUser',
      'currentSession',
    ]),
    locale() {
      return localStorage.getItem('_azor_locale')
    },
    inDevelopmentMode() {
      return process.env.VUE_APP_MODE === 'development'
    },
    manualPairingUser() {
      return ['parovac', 'parovac2'].includes(this.currentUser)
    },
  },
  mounted: function () {
    const menuCheck = localStorage.getItem('menuState')
    //check if menu should already be small due to saved menuState or due to browser loading for the first time (without saved menuState) and small window size -> load smaller menu on mobile by default
    if (menuCheck === 'large') {
      this.fullNavShown = 'large'
      document.body.className = 'largeMenu'
    } else {
      this.fullNavShown = 'small'
      document.body.className = 'smallMenu'
      // document.getElementById('logo-sm').style.display = 'none'
      // document.getElementById('logo-bg').style.display = 'block'
    }
  },
  created() {
    this.fullNavShown = localStorage.getItem('menuState')
  },
  methods: {
    toggleMenu() {
      if (this.fullNavShown == 'small') {
        this.fullNavShown = 'large'
        document.body.className = document.body.className.replace(
          'smallMenu',
          'largeMenu'
        )
        localStorage.setItem('menuState', 'large')
        // document.getElementById('logo-sm').style.display = 'none'
        // document.getElementById('logo-bg').style.display = 'block'
      } else {
        document.body.className = document.body.className.replace(
          'largeMenu',
          'smallMenu'
        )
        this.fullNavShown = 'small'
        localStorage.setItem('menuState', 'small')
        // document.getElementById('logo-sm').style.display = 'block'
        // document.getElementById('logo-bg').style.display = 'none'
      }
    },
    setLanguage(language) {
      this.$store.dispatch('users/setLanguage', language)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../assets/styles/variables.scss'
.big-nav-toggle
  position: fixed
  top: 80px
  left: 191px
  border: 1px solid $gray-100
  background-color: $gray-300
  border-radius: 25px
  padding: 8px
  &:hover
    background-color: $blue-600
    color: $white

.small-nav-toggle
  background-color: $white
  border-radius: 25px
  padding-left: 1px
  color: $blue-800
  &:hover
    background-color: $blue-600
    color: $white

.left-nav-wrap-large
  margin-top: 30px

.language-active
  cursor: not-allowed
  color: $black

.language-active:hover
  cursor: not-allowed
  color: $black

.language-inactive
  color: $blue-600

.language-inactive:hover
  color: $blue-600

.language-switcher
  span
    margin: 0 3px 0 3px

.subnav
  margin-left: 30px
</style>
