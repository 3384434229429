// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createApp, provide, h } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/store'
import '/public/ads-prebid.js'
import Cookies from 'js-cookie'

/* UI kit framework */
import UiKit from 'uikit'
import UiKitIcons from 'uikit/dist/js/uikit-icons'
UiKit.use(UiKitIcons)
/**/

/* Common */
window.getCookie = function (name) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length == 2) return parts.pop().split(';').shift()
}
/**/

/* GraphQL */
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_URL || 'http://localhost:3000/graphql',
  credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('CSRF-TOKEN')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Csrf-Token': token ? token : '',
    },
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

/**/

/* Yaml i18n support */
import { createI18n } from 'vue-i18n'
import en from 'js-yaml-loader!./locales/en.yaml'
import cs from 'js-yaml-loader!./locales/cs.yaml'
import { pluralizationRules } from './locales/pluralizationRules'
const locale = localStorage.getItem('_azor_locale')
const handleMissing = (locale, key) => {
  console.warn(
    `[intlify] Not found '${key}' key in '${locale}' locale messages`
  )
  const keyPath = key.split('.')
  return keyPath[keyPath.length - 1]
}
const i18n = createI18n({
  legacy: false,
  locale: locale ? locale : 'en',
  pluralizationRules: {
    cs: pluralizationRules.cs,
  },
  messages: {
    en,
    cs,
  },
  missing: handleMissing,
})
window.i18n = i18n.global
/**/

import { setLocale } from 'yup'
let yupLocale
if (locale === 'cs') yupLocale = cs
else yupLocale = en
setLocale(yupLocale.validators)

// let locale = store.state.users.user.locale || 'en';

import moment from 'moment'
moment.locale(locale)
window.moment = moment

import Debounce from '@/components/BaseDebounce.vue'
import DropdownList from '@/components/DropdownList.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

import { flashMessages } from '@/mixins/flashMessages.js'
import { helpers } from '@/mixins/helpers.js'
import titleMixin from '@/mixins/titleMixin.js'
import _debounce from '@/mixins/debounce'

import filters from '@/filters/filters.js'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
if (process.env.NODE_ENV !== 'development') {
  /* Sentry */

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.Vue({ undefined, attachProps: true })], //TODO properly integrate sentry
    normalizeDepth: 8,
  })

  Sentry.configureScope((scope) => {
    scope.setExtra('state', store.state)
  })
}

import VueGtag from 'vue-gtag-next'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})
  .use(router)
  .use(store)
  .use(i18n)
  .use(VueClipboard)
  .component('Debounce', Debounce)
  .component('DropdownList', DropdownList)
  .component('Breadcrumbs', Breadcrumbs)
  .mixin(helpers)
  .mixin(flashMessages)
  .mixin(titleMixin)
  .mixin(_debounce)
  .directive('focus', {
    inserted: (el, binding) => {
      if (binding.value) {
        el.focus()
      }
    },
  })

app.config.globalProperties.$filters = filters

if (window.canRunAds) {
  app.use(VueGtag, {
    property: [
      {
        id: 'G-ZYS9JHZEF3',
        params: {
          send_page_view: true,
        },
      },
      {
        id: 'UA-184032776-2',
        params: {
          send_page_view: true,
        },
      },
    ],
  })
} else {
  app.use(VueGtag, {})
}

app.mount('#app')
