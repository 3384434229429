import ApiFetcher from '@/services/ApiFetcher'

export default class Rules extends ApiFetcher {
  static getRules() {
    return ApiFetcher.connections()
      .ApiV1Call.get('/rules')
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static createRule(rule) {
    const finalRule = {
      name: rule.name,
      note: rule.note,
      active: true,
      logical_operator: rule.logicalOperator,
      filters: [],
    }

    finalRule.filters = this.normalizeRuleFilters(rule)
    finalRule.configs = this.normalizeRuleConfigs(rule)

    return ApiFetcher.connections()
      .ApiV1Call.post('/rules', finalRule)
      .then((response) => {
        if (response.status === 201) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static changeRuleStatus(params) {
    return ApiFetcher.connections()
      .ApiV1Call.put(`/rules/${params.rule.id}`, {
        active: params.active,
      })
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static toggleRuleActivity(rule) {
    const params = {
      set_id: rule.product_set_id,
      skip_csrf: true,
    }

    const payload = {
      id: rule.id,
      active: !rule.active,
    }

    const queryString = ApiFetcher.buildQueryString(params)

    return ApiFetcher.ApiCall.patch(`/rules/${rule.id}?${queryString}`, payload)
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static getRule(ruleId) {
    return ApiFetcher.connections()
      .ApiV1Call.get(`/rules/${ruleId}`)
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static deleteRule(ruleId) {
    const params = {
      skip_csrf: true,
    }

    const queryString = ApiFetcher.buildQueryString(params)

    return ApiFetcher.connections()
      .ApiV1Call.delete(`/rules/${ruleId}?${queryString}`)
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static duplicateGroup(group) {
    const params = {
      skip_csrf: true,
    }

    const queryString = ApiFetcher.buildQueryString(params)

    return ApiFetcher.ApiCall.post(
      `/product_sets/${group.id}/duplicate?${queryString}`
    )
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static updateRuleNote(rule) {
    return ApiFetcher.connections()
      .ApiV1Call.put(`/rules/${rule.id}`, rule)
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static getRuleFilterOptions() {
    return ApiFetcher.connections()
      .ApiV1Call.get('rules/parameters')
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static getRuleConfigOptions() {
    return ApiFetcher.connections()
      .ApiV1Call.get('rules/config_parameters')
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data.groups)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static getRulesHistory() {
    return ApiFetcher.connections()
      .ApiV1Call.get('rules/history')
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static getCategories() {
    const params = {
      skip_csrf: true,
    }

    const queryString = ApiFetcher.buildQueryString(params)

    return ApiFetcher.connections()
      .ApiV1Call.get(`/rules/categories?${queryString}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static copyRule(payload) {
    return ApiFetcher.connections()
      .ApiV1Call.post(`/rules/${payload.id}/copy`, {
        new_client_id: payload.client_id,
        name: payload.name,
      })
      .then((response) => {
        if (response.status === 201) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static getRuleProducts(rule) {
    let finalRule
    if (rule.expirationUnit) {
      finalRule = {
        name: rule.name,
        note: rule.note,
        active: true,
        logical_operator: rule.logicalOperator,
        filters: [],
      }

      finalRule.filters = this.normalizeRuleFilters(rule)
      finalRule.configs = this.normalizeRuleConfigs(rule)
    } else {
      finalRule = rule
    }

    const params = {
      skip_csrf: true,
    }

    const queryString = ApiFetcher.buildQueryString(params)

    return ApiFetcher.connections()
      .ApiV1Call.post(`/rules/products_preview?${queryString}`, finalRule)
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static exportProducts(payload) {
    let finalRule
    if (payload.rule.expirationUnit) {
      finalRule = {
        name: payload.rule.name,
        note: payload.rule.note,
        active: true,
        logical_operator: payload.rule.logicalOperator,
        filters: [],
      }

      finalRule.filters = this.normalizeRuleFilters(payload.rule)
      finalRule.configs = this.normalizeRuleConfigs(payload.rule)
    } else {
      finalRule = payload.rule
    }

    finalRule.parameters = payload.visibleCols
    finalRule.applied = payload.applied
    return ApiFetcher.connections()
      .ApiV1Call.post('/rules/products_export', finalRule)
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${
              finalRule.name ? finalRule.name : Date.now()
            }-products_export.csv`
          )
          document.body.appendChild(link)
          link.click()
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static saveUpdatedRule(rule) {
    const params = {
      skip_csrf: true,
    }

    const finalRule = {
      name: rule.name,
      note: rule.note,
      active: true,
      logical_operator: rule.logicalOperator,
      filters: [],
    }

    finalRule.filters = this.normalizeRuleFilters(rule)
    finalRule.configs = this.normalizeRuleConfigs(rule)

    const queryString = ApiFetcher.buildQueryString(params)

    return ApiFetcher.connections()
      .ApiV1Call.put(`/rules/${rule.id}?${queryString}`, finalRule)
      .then((response) => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          throw 'Unhandled response status'
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static normalizeRuleFilters(rule) {
    if (rule.global) {
      rule.filters.forEach((filter) => {
        filter.value = [true]
        delete filter.values
        delete filter.valid
      })
      return rule.filters
    }
    rule = JSON.parse(JSON.stringify(rule))
    rule.filters.forEach((filter) => {
      if (
        filter.parameter_name !== 'categories' &&
        filter.parameter_name !== 'stores' &&
        filter.type !== 'float' &&
        filter.parameter_name !== 'store_type' &&
        filter.type !== 'boolean' &&
        filter.action !== 'exists'
      ) {
        filter.value = filter.value.map((value) => value.trim())
      }
      delete filter.actions
      delete filter.type
      delete filter.valid
      delete filter.options
    })
    return rule.filters
  }

  static normalizeRuleConfigs(rule) {
    const configs = []
    for (const config in rule.configs) {
      rule.configs[String(config)].map((configType) => {
        if (config === 'azor') {
          const azor = {
            type: 'azor',
            action: configType.action,
          }
          if (configType.action === 'portal_shop_filter') {
            azor.value = configType.value
            azor.positive = configType.positive
          } else {
            azor.value = configType.value
          }
          configs.push(azor)
        } else if (config === 'price_filter') {
          configs.push({
            type: 'price_filter',
            action: 'range',
            disable_price_filter: configType.disablePriceFilter,
            value: configType.value,
            end_value: configType.end_value,
          })
        } else if (config === 'substitution') {
          const finalSubstitution = {
            type: 'substitution',
            action: configType.action,
            parameter_name: configType.parameterName,
          }
          if (
            configType.action === 'replace' ||
            configType.action === 'modify' ||
            configType.action === 'custom_parameter'
          ) {
            finalSubstitution.value = configType.value.trim()
          }
          if (
            configType.action === 'replace' &&
            configType.parameterName === 'create_custom_parameter'
          ) {
            finalSubstitution.parameter_name = configType.newParameterName
          } else if (configType.action === 'modify') {
            finalSubstitution.modified_value = configType.newValue
          } else if (configType.action === 'custom_parameter') {
            if (configType.newParameter === 'create_custom_parameter') {
              finalSubstitution.new_parameter = `user_fields.${configType.newParameterName}`
            } else {
              finalSubstitution.new_parameter = configType.newParameter
            }
          } else if (configType.action === 'combine') {
            finalSubstitution.value = configType.value
            finalSubstitution.delimiter = configType.delimiter
          } else if (configType.action === 'combine_to_array') {
            finalSubstitution.value = configType.value
            finalSubstitution.array = true
            finalSubstitution.action = 'combine'
          }
          configs.push(finalSubstitution)
        } else if (config === 'nasoska') {
          let value = configType.value
          if (configType.action === 'filterpct') {
            value = parseFloat(configType.value)
          } else if (configType.action === 'limit') {
            value = parseInt(configType.value)
          } else if (typeof configType.value === 'string') {
            value = configType.value.trim()
          }
          configs.push({
            type: 'nasoska',
            action: configType.action,
            value: value,
          })
        }
      })
    }
    return configs
  }

  static async restoreRules(version) {
    try {
      const response = await ApiFetcher.connections().ApiV1Call.post(
        'rules/restore',
        {
          version: version,
        }
      )
      if (response.status === 200) {
        return Promise.resolve(response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static convertRuleFiltersValuesByOperator(ruleToConvert) {
    const rule = JSON.parse(JSON.stringify(ruleToConvert))
    if (rule.recipe.recipes.length > 0) {
      rule.recipe.recipes.forEach((recipe) => {
        if (recipe.operator == '-') {
          recipe.value *= -1
        } else {
          recipe.value = parseInt(recipe.value)
        }
      })
    }
    return rule
  }

  static async updateRulesPositions(positions) {
    try {
      const response = await ApiFetcher.connections().ApiV1Call.post(
        'rules/update_positions',
        positions
      )

      if (response.status === 200) {
        return Promise.resolve(response)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getNasoskaDefaultConfiguration() {
    try {
      const response = await ApiFetcher.connections().ApiV1Call.get(
        'rules/nasoska_default_configuration'
      )

      if (response.status === 200) {
        return Promise.resolve(response)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getRuleProductCount(id) {
    try {
      const response = await ApiFetcher.connections().ApiV1Call.post(
        'rules/product_count',
        {
          id,
        }
      )

      if (response.status === 200) {
        return Promise.resolve(response.data)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
