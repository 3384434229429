import type {
  TFilter,
  TTableFilter,
  TTableSort,
} from '@/components/table/types'
import type { Commit } from 'vuex'

export const namespaced = true

interface TSort {
  [tableId: string]: [string, 'up' | 'down']
}

export type TImportModalState = {
  filters: TFilter
  sorters: TSort
}

export const state: TImportModalState = {
  filters: {},
  sorters: {},
}

export const mutations = {
  CHANGE_TABLES_FILTER(state: TImportModalState, data: TTableFilter) {
    if (!state.filters[data.tableId]) state.filters[data.tableId] = {}
    state.filters[data.tableId][data.column] = data.value
  },

  CHANGE_TABLES_SORT(state: TImportModalState, data: TTableSort) {
    state.sorters[data.tableId] = [data.column, data.value]
  },
}

export const actions = {
  changeFilter({ commit }: { commit: Commit }, filterData: TTableFilter) {
    commit('CHANGE_TABLES_FILTER', filterData)
  },

  changeSort({ commit }: { commit: Commit }, sortData: TTableSort) {
    commit('CHANGE_TABLES_SORT', sortData)
  },
}
