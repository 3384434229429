import { isEmpty } from 'lodash'

export const helpers = {
  methods: {
    isEmpty(variable) {
      return isEmpty(variable)
    },

    deepCopy(object) {
      return JSON.parse(JSON.stringify(object))
    },

    /**
     * Get currency sign of given locale and currency or from global VueX store by default.
     * @param {String} locale 'cs'
     * @param {String} currency 'CZK'
     * @param {Object} store Vuex store
     *
     * @returns String
     */
    currencySign(locale = undefined, currency = undefined) {
      if (!locale) locale = window.store.state.users.locale
      if (!currency)
        currency = window.store.state.instances.instance.options.currency

      if (!locale || !currency) return ''

      return (0)
        .toLocaleString(locale, {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()
    },
  },
}
