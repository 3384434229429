<template>
  <div :key="buildId">
    <div :class="{ 'gray-overlay': newUpdateAvailable }" />
    <aside v-if="currentSession" id="left-col" class="uk-light">
      <Navigation />
    </aside>
    <NewUpdateAlert
      v-if="newUpdateAvailable"
      @new-update-alert-closed="postponeUpdate()"
    />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Smartlook v-if="currentSession" />
  </div>
</template>

<script>
import Navigation from '@/components/navigation'
import NewUpdateAlert from '@/components/NewUpdateAlert'
import { getLatestBuild } from '@/helpers/helpers.js'
import { mapState } from 'vuex'
import { reactive, toRefs } from 'vue'
import Smartlook from '@/components/Smartlook'

export default {
  components: { Smartlook, Navigation, NewUpdateAlert },
  setup() {
    const event = reactive({
      buildId: '',
    })

    getLatestBuild().then((buildId) => {
      event.buildId = buildId
    })

    const reloadAppTriggered = sessionStorage.getItem('reload_app_triggered')
    if (reloadAppTriggered) {
      sessionStorage.removeItem('reload_app_triggered')
      sessionStorage.removeItem('new_update_available')
    }
    return { ...toRefs(event) }
  },
  data() {
    return { buildId: '' }
  },
  computed: {
    ...mapState('users', ['newUpdateAvailable', 'currentSession']),
  },
  methods: {
    postponeUpdate() {
      this.$store.dispatch('users/setUpdateAvailability', false)
    },
  },
}
</script>

<style src="../node_modules/uikit/dist/css/uikit.min.css" lang="css"></style>
<style src="@/assets/styles/multiselect.css" lang="css"></style>
<style src="@/assets/styles/app.sass" lang="sass"></style>
