<!-- eslint-disable vue/no-v-html-->
<template>
  <div
    v-if="confirmation"
    class="uk-width-1-1 gdpr-confirmation uk-position-bottom uk-box-shadow-hover-small"
  >
    <div uk-grid class="uk-flex-between uk-flex uk-flex-middle">
      <div
        class="uk-margin-large-left uk-padding-small"
        v-html="$t('smartlook.info')"
      />
      <div
        uk-grid
        class="uk-float-right uk-grid-small uk-padding-small uk-padding-remove-top"
      >
        <button class="uk-button uk-button-default" @click="disagree()">
          {{ $t('smartlook.disagree') }}
        </button>
        <button class="uk-button uk-button-primary" @click="agree(false)">
          {{ $t('smartlook.agree') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import SmartlookClient from 'smartlook-client'
import { useI18n } from 'vue-i18n'
export default {
  name: 'Smartlook',
  setup() {
    const smartlookApiKey = ref(process.env.VUE_APP_SMARTLOOK_KEY)

    const showConfirmation = () => {
      confirmation.value = true
    }

    const initSmartlook = () => {
      if (window.smartlook) {
        return false
      }

      SmartlookClient.init(smartlookApiKey.value)

      window.smartlook(() => {
        if (
          window.smartlook.consent.api == null &&
          !localStorage.getItem('azor_smartlook_gdpr')
        ) {
          showConfirmation()
        }
      })
    }

    initSmartlook()

    const confirmation = ref(false)
    const i18n = useI18n()

    const hideConfirmation = () => {
      confirmation.value = false
    }

    const disagree = () => {
      SmartlookClient.disable()
      localStorage.setItem('azor_smartlook_gdpr', 'disagreed')
      hideConfirmation()
    }

    const agree = (includingIP) => {
      hideConfirmation()
      let consentText =
        i18n.t('smartlook.info') + ' ' + i18n.t('smartlook.agree')
      if (includingIP) {
        consentText += i18n.t('smartlook.including_IP_address')
      }

      SmartlookClient.consentForms(consentText)
      SmartlookClient.consentAPI(consentText)
      SmartlookClient.consentIP(consentText)

      localStorage.setItem(
        'azor_smartlook_gdpr',
        includingIP ? 'agreed_with_IP' : 'agreed_without_IP'
      )
    }

    return {
      confirmation,
      showConfirmation,
      hideConfirmation,
      agree,
      disagree,
    }
  },
}
</script>

<style scoped lang="sass">
@import '../assets/styles/variables.scss'
.gdpr-confirmation
  z-index: 1002
  background-color: $gray-200
  border: 1px solid $gray-400
</style>
