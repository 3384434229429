<template>
  <div
    id="newUpdateAlert"
    class="uk-animation-slide-top-medium new-update-alert"
  >
    <div class="uk-alert" uk-alert>
      <div class="uk-flex uk-flex-between uk-flex-center uk-flex-middle">
        <h4 class="uk-margin-top" v-text="$t('global.New_update_available')" />
        <div>
          <a @click="reload()">
            <button
              class="uk-button uk-button-primary"
              type="button"
              v-text="$t('global.Update')"
            />
          </a>
          <button
            class="uk-button uk-button-default"
            type="button"
            @click="closeAlert()"
            v-text="$t('global.Close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
export default {
  methods: {
    closeAlert() {
      UIkit.alert('#newUpdateAlert').close()
      this.$emit('new-update-alert-closed', true)
    },
    reload() {
      sessionStorage.setItem('reload_app_triggered', true)
      window.location.reload(true)
    },
  },
}
</script>

<style lang="sass" scoped></style>
