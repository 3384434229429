import { flashMessages } from '@/mixins/flashMessages'

export const helpers = {
  columnTranslation(column, t) {
    if (column.name.includes('user_fields')) {
      const regex = /user_fields\.(.*)/
      return `${'products.fields.user_field'}: ${regex.exec(column.name)[1]}`
    } else {
      return t(`products.fields.${column.name}`)
    }
  },

  dictReverse(object) {
    const newObject = {}
    const reverseObject = Object.keys(object).reverse()
    reverseObject.forEach((key) => {
      newObject[String(key)] = object[String(key)]
    })
    return newObject
  },

  hasDuplicates(array, field) {
    const tmpArray = array.map((obj) => obj[String(field)])
    return tmpArray.some((item, index) => tmpArray.indexOf(item) !== index)
  },

  deepCopy(object) {
    return JSON.parse(JSON.stringify(object))
  },

  getHostName(url) {
    const matches = url.match(/^https?:\/\/w?w?w?\.?([^/?#]+)(?:[/?#]|$)/i)
    return matches && matches[1]
  },

  deepValue(obj, path) {
    path = path.split('.')
    for (let i = 0, len = path.length; i < len; i++) {
      obj = obj[path[Number(i)]]
    }
    return obj
  },

  specifiedObjectKeys(object, keys) {
    return Object.keys(object)
      .filter((i) => keys.includes(i))
      .reduce((acc, key) => {
        acc[String(key)] = object[String(key)]
        return acc
      }, {})
  },

  regExpEscape(literal_string) {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\\/\\^$|#\s,]/g, '\\$&')
  },

  serializeBasicRoute(route) {
    return {
      name: route.name,
      params: route.params,
      query: route.query,
      hash: route.hash,
      meta: route.meta,
      fullPath: route.fullPath,
      path: route.path,
    }
  },
  capitalize(string) {
    if (string) {
      return string.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    }
    return string
  },
  joinArrayField(field) {
    return Array.isArray(field) ? field.join(', ') : field
  },
  isValidURL(string) {
    const res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g // eslint-disable-line security/detect-unsafe-regex
    )
    return res !== null
  },
  animate(elementId) {
    const doc = document.getElementById(elementId)
    doc.addEventListener('animationend', () => {
      doc.classList.remove('uk-animation-shake')
    })
    doc.classList.add('uk-animation-shake')
  },
}

export const checkBuildVersion = async (store) => {
  try {
    const currentBuild = sessionStorage.getItem('current_build')

    const latestBuildInfo = await fetch(
      '/app/build.json?t=' + Math.random().toString(36).substring(2, 12)
    )

    const latestBuildInfoJson = await latestBuildInfo.json()
    const latestBuild = latestBuildInfoJson.build

    const newUpdateAvailable = sessionStorage.getItem('new_update_available')

    if ((currentBuild && currentBuild != latestBuild) || newUpdateAvailable) {
      sessionStorage.setItem('new_update_available', true)
      store.dispatch('users/setUpdateAvailability', newUpdateAvailable)
    }
  } catch (error) {
    console.log(error)
  }
}

export const getLatestBuild = async () => {
  try {
    const latestBuildInfo = await fetch(
      '/app/build.json?t=' + Math.random().toString(36).substring(2, 12)
    )
    const latestBuildInfoJson = await latestBuildInfo.json()
    const latestBuild = latestBuildInfoJson.build

    sessionStorage.setItem('current_build', latestBuild)
    return Promise.resolve(latestBuild)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const copy = (copyText) => {
  navigator.clipboard.writeText(copyText)
  flashMessages.methods.flashSuccess('copied')
}

export const arraysDifferences = (arr1, arr2) => {
  return arr1
    .filter((item) => !arr2.includes(item))
    .concat(arr2.filter((x) => !arr1.includes(x)))
}

export const formDataToRowData = (formData) => {
  const rowData = {}
  for (const key of formData.keys()) {
    if (key.includes('[]'))
      rowData[key.replace('[]', '')] = formData.getAll(key)
    else rowData[key.toString()] = formData.get(key)
  }
  return rowData
}

const rowTypeOrder = {
  danger: 0,
  warning: 1,
  success: 2,
  default: 3,
}

export const sortByType = (rows) => {
  return rows.sort((a, b) => {
    return rowTypeOrder[a.rowType] - rowTypeOrder[b.rowType]
  })
}

export const getCookie = (name) => {
  const cookieValue = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(`${name}=`))

  return cookieValue ? decodeURIComponent(cookieValue.split('=')[1]) : null
}
