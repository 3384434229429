export default {
  methods: {
    _debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer)
      }
      this.$_debounceTimer = setTimeout(() => {
        method()
      }, timer)
    },
  },
  created() {
    this.$_debounceTimer = null
  },
}
