import ApiFetcher from '@/services/ApiFetcher'
import { TStoreUser } from '@/types/store/user'

export default class Users extends ApiFetcher {
  static async getUserInfo() {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.get(
        '/permissions'
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async selectInstance(instance: string) {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.get(
        '/permissions',
        {
          params: {
            client_id: instance,
          },
        }
      )

      if (process.env.VUE_APP_MODE === 'development') {
        sessionStorage.setItem('azor_instance_id', instance)
        localStorage.setItem('azor_instance_id', instance)
      }

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getUsers() {
    try {
      const response = await ApiFetcher.connections().ApiCall.get('/users')

      return response.data
    } catch (e) {
      return new Error('Unhandled response status')
    }
  }

  static async createUser(params: TStoreUser) {
    const response = await ApiFetcher.connections().ApiCall.post(
      `/users`,
      params
    )

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Unhandled response status')
    }
  }

  static async updateUser(params: { id: number; data: TStoreUser }) {
    const response = await ApiFetcher.connections().ApiCall.put(
      `/users/${params.id}`,
      params.data
    )

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Unhandled response status')
    }
  }

  static async deleteUser(id: number) {
    const response = await ApiFetcher.connections().ApiCall.delete(
      `/users/${id}`
    )

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Unhandled response status')
    }
  }
}
