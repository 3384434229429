import Statistics from '@/services/Statistics'
import NProgress from 'nprogress'
import { helpers } from '@/helpers/helpers'

export const namespaced = true

export const state = {
  loadingStats: true,
  statistics: {},
  filterParameters: {},
  products: {},
  shops: [],
  parsedFilters: [],
}

export const mutations = {
  SET_FILTER_PARAMETERS(state, data) {
    state.filterParameters = data
  },
  SET_STATISTICS(state, data) {
    state.statistics = data
  },
  SET_PRODUCTS(state, data) {
    state.products = data
  },
  SET_LOADING_STATS(state, data) {
    state.loadingStats = data
  },
  SET_SHOPS(state, shops) {
    state.shops = shops
  },
  SET_PARSED_FILTERS(state, filters) {
    state.parsedFilters = filters
  },
}

export const actions = {
  async getFilterParameters({ commit }) {
    try {
      const data = await Statistics.getFilterParameters()
      commit('SET_FILTER_PARAMETERS', data.filters)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getFilteredStatistics({ commit, state }, data) {
    commit('SET_LOADING_STATS', true)
    NProgress.start()
    const body = {}
    data.forEach((parameter) => {
      body[`${parameter}_changed`] = true
    })
    Object.keys(state.filterParameters).forEach((parameter) => {
      if (!body[`${parameter}_changed`]) {
        body[`${parameter}_changed`] = false
      }
    })

    commit('SET_PARSED_FILTERS', body)

    try {
      const response = await Statistics.getFilteredStatistics(body)
      commit('SET_STATISTICS', helpers.dictReverse(response.days))
      commit('SET_LOADING_STATS', false)

      let shops = []
      for (const day in response.days) {
        // eslint-disable-next-line no-prototype-builtins
        if (!response.days.hasOwnProperty(day)) continue
        shops = shops.concat(Object.keys(response.days[String(day)]))
      }

      commit('SET_SHOPS', [...new Set(shops)])

      NProgress.done()
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getStatisticsProducts({ commit, state }, data) {
    const body = { filters: {} }

    data.checkedParameters.forEach((parameter) => {
      body.filters[`${parameter}_changed`] = true
    })
    Object.keys(state.filterParameters).forEach((parameter) => {
      if (!body.filters[`${parameter}_changed`]) {
        body.filters[`${parameter}_changed`] = false
      }
    })

    body.date = data.date
    body.shop = data.shop

    try {
      const response = await Statistics.getStatisticsProducts(body)
      commit('SET_PRODUCTS', response)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async exportProducts({ state }, payload) {
    const request = {
      filters: state.parsedFilters,
      shop: payload.shop,
      start_date: payload.start,
      end_date: payload.end,
    }

    try {
      const response = await Statistics.exportProducts(request)
      if (response.status == 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${request.start_date}_${request.end_date}-stats_export.csv`
        )
        document.body.appendChild(link)
        link.click()
      }
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
