import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store'
import { checkBuildVersion } from '@/helpers/helpers'
import { trackRouter } from 'vue-gtag-next'
import { flashMessages } from '@/mixins/flashMessages'

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routeHistory = createWebHistory()
const router = createRouter({
  mode: 'history',
  history: routeHistory,
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/app/logout',
      name: 'logOut',
    },
    {
      path: '/app/login',
      name: 'logIn',
      component: loadView('LogIn')
    },
    {
      path: '/app',
      alias: '',
      redirect: {
        name: 'products',
      },
    },
    {
      path: '/app/rules',
      alias: '/rules',
      name: 'rules',
      component: loadView('Rules'),
    },
    {
      path: '/app/statistics',
      redirect: '/app/dailyCheck/statistics',
    },
    {
      path: '/app/rules/add',
      alias: '/rules/add',
      name: 'add-rule',
      component: loadView('Rule'),
    },
    {
      path: '/app/rules/:ruleId/:global?',
      alias: '/rules/:ruleId/:global?',
      name: 'rule-editation',
      component: loadView('Rule'),
    },
    {
      path: '/app/rules/history/:version/:ruleId',
      alias: '/rules/history/:version/:ruleId',
      name: 'rule-view',
      component: loadView('Rule'),
    },
    {
      path: '/app/rules/history/:version',
      alias: '/rules/history/:version',
      name: 'rules-history',
      component: loadView('Rules'),
    },
    {
      path: '/app/approvalSystem',
      alias: '/approvalSystem',
      name: 'approval-system',
      component: loadView('ApprovalSystem'),
    },
    {
      path: '/app/products/:productId?/:tab?',
      alias: '/products/:productId?/:tab?',
      name: 'products',
      component: loadView('Products'),
    },
    {
      path: '/app/files',
      alias: '/files',
      name: 'files',
      component: loadView('Files'),
    },
    {
      path: '/app/matcher/:query?',
      alias: '/matcher/:query?',
      name: 'azor-matcher',
      component: loadView('Matcher'),
    },
    {
      path: '/app/instances',
      alias: '/instances',
      name: 'instances',
      component: loadView('Instances'),
    },
    {
      path: '/app/instances/add',
      alias: '/instances/add',
      name: 'add-instance',
      component: loadView('Instance'),
    },
    {
      path: '/app/instances/:instanceName',
      alias: '/instances/:instanceName',
      name: 'edit-instance',
      component: loadView('Instance'),
    },
    {
      path: '/app/unauthorized',
      alias: '/unauthorized',
      name: 'unauthorized',
      component: loadView('401'),
    },
    {
      path: '/app/userPairings/files',
      alias: '/userPairings/files',
      name: 'user-pairings-files',
      component: loadView('UserPairingsFiles'),
    },
    {
      path: '/app/userPairings',
      alias: '/userPairings',
      name: 'user-pairings',
      component: loadView('UserPairings'),
    },
    {
      path: '/app/userPairings/admin',
      alias: '/userPairings/admin',
      name: 'user-pairings-admin',
      component: loadView('UserPairingsAdmin'),
    },
    {
      path: '/app/userPairings/:projectId',
      alias: '/userPairings/:projectId',
      name: 'edit-user-pairings-project',
      component: loadView('UserPairingsProject'),
    },
    {
      path: '/app/userPairings/new',
      alias: '/userPairings/new',
      name: 'new-user-pairings-project',
      component: loadView('UserPairingsProject'),
    },
    {
      path: '/app/dailyCheck/:tab?',
      alias: '/dailyCheck/:tab?',
      name: 'daily-check',
      component: loadView('DailyCheck'),
    },
    {
      path: '/app/users',
      alias: '/users',
      name: 'users',
      component: loadView('Users'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  store.commit('SET_ROUTE', to)
  if (to.path === '/app/logout') {
    flashMessages.methods.flashSuccess('login.log_out_successfully', null, null, true, 1000)
    next({ name: 'logIn' })
  } else if (to.name === 'products' && from.name === 'products') {
    next()
  } else {
    store
      .dispatch('users/getUserInfo')
      .then(() => {
        if (
          !store.state.users.admin &&
          store.state.users.permissions.length === 1 &&
          store.state.users.permissions.includes('pairing') &&
          to.name === 'products'
        ) {
          next(false)
          // for user pairing use only cs language
          localStorage.setItem('_azor_locale', 'cs')
          router.push({ name: 'user-pairings' })
        } else {
          if (to.name === 'logIn'){
            next({name: 'products'})
          }
          next()
        }
      })
      .catch((e) => {
        console.log(e)
        if (to.name === 'logIn') next()
        else next({ name: 'logIn', query: { redirect: to.fullPath }, })
      })
  }
})

router.afterEach(() => {
  checkBuildVersion(store)
})

trackRouter(router)

export default router
