export const pluralizationRules = {
  cs: (choice) => {
    if (choice == 0 || choice > 4 || !choice) {
      return 0
    }
    if (choice == 1) {
      return 1
    }
    if (choice > 1 && choice <= 4) {
      return 2
    }
  },
}
