import type { GetterTree, Commit } from 'vuex'
import type {
  TSearchFilters,
  TFilter,
  TFiltersList,
  TExportSettings,
} from '@/components/products/ProductExport/types'

export const namespaced = true

export interface IProductExport {
  currentExportFile: File | null
  searchFilters: TSearchFilters | null
  queryFilter: string | null
  shopFilter: TFilter | null
  portalFilter: TFilter | null
  categoryFilter: TFilter | null
  producerFilter: TFilter | null
  tagFilter: TFilter | null
  pairingFilter: TFilter | null
  recomputeProductTrigger: boolean
  exportSettings: TExportSettings
  notificationOpened: boolean
}

export const state = {
  recomputeProductTrigger: false,
  currentExportFile: null,
  searchFilters: null,
  exportSettings: {
    fileFormat: 'csv',
    exportLayout: 'vertical',
    previewProducts: [],
    selectedProductColumns: [],
    selectedResultColumns: [],
    selectedCalculatedColumns: [],
    mainColumnsOrder: [],
    selectedVerticalColumns: [],
    exportName: '',
  },
  notificationOpened: false,
}

export type TExportSettingsKey = keyof TExportSettings
export type TExportSettingsValue = TExportSettings[TExportSettingsKey]

export const mutations = {
  SET_EXPORT_FILE(state: IProductExport, currentExportFile: File | null) {
    state.currentExportFile = currentExportFile
  },
  SET_SEARCH_FILE(state: IProductExport, searchFilters: TSearchFilters | null) {
    state.searchFilters = searchFilters
  },
  TRIGGER_RECOMPUTE_PRODUCT(state: IProductExport) {
    state.recomputeProductTrigger = !state.recomputeProductTrigger // Toggling the value to make it reactive
  },
  ADD_TO_EXPORT_SETTINGS(
    state: IProductExport,
    payload: {
      key: TExportSettingsKey
      value: TExportSettingsValue
    }
  ) {
    if (payload.key in state.exportSettings) {
      ;(state.exportSettings[payload.key] as TExportSettingsValue) =
        payload.value
    } else {
      console.error(`Key ${payload.key} is not a valid key in exportSettings`)
    }
  },
  SET_NOTIFICATION_OPENED(state: IProductExport, notificationOpened: boolean) {
    state.notificationOpened = notificationOpened
  },
}

export const getters: GetterTree<IProductExport, unknown> = {
  filtersList: (state, getters): TFiltersList => ({
    query: getters.queryFilter,
    shop: getters.shopFilter,
    portal: getters.portalFilter,
    category: getters.categoryFilter,
    producer: getters.producerFilter,
    tag: getters.tagFilter,
    pairing: getters.pairingFilter,
  }),
  queryFilter: (state: IProductExport) =>
    state.searchFilters ? state.searchFilters.query : null,
  shopFilter: (state: IProductExport) => {
    if (state.searchFilters) {
      return state.searchFilters.filters.find(
        (filter) => filter.field === 'store'
      )
    }
    return null
  },
  portalFilter: (state: IProductExport) => {
    if (state.searchFilters) {
      return (
        state.searchFilters.filters.find(
          (filter) => filter.field === 'portal'
        ) || null
      )
    }
    return null
  },
  categoryFilter: (state: IProductExport) => {
    if (state.searchFilters) {
      return (
        state.searchFilters.filters.find(
          (filter) => filter.field === 'category'
        ) || null
      )
    } else {
      return null
    }
  },
  producerFilter: (state: IProductExport) => {
    if (state.searchFilters) {
      return (
        state.searchFilters.filters.find(
          (filter) => filter.field === 'producer'
        ) || null
      )
    }
    return null
  },
  tagFilter: (state: IProductExport) => {
    return state.searchFilters
      ? state.searchFilters.filters.find((filter) => filter.field === 'tag') ||
          null
      : null
  },
  pairingFilter: (state: IProductExport) => {
    if (state.searchFilters) {
      return (
        state.searchFilters.filters.find(
          (filter) => filter.field === 'pairing'
        ) || null
      )
    }
    return null
  },
  isLayoutHorizontal: (state: IProductExport) => {
    return state.exportSettings.exportLayout === 'horizontal'
  },
  mainColumnsOrdered: (state: IProductExport) => {
    return state.exportSettings.mainColumnsOrder.map((group) => {
      switch (group.type) {
        case 'product':
          return state.exportSettings.selectedProductColumns
        case 'result':
          return state.exportSettings.selectedResultColumns
        default:
          return state.exportSettings.selectedCalculatedColumns
      }
    })
  },
  anyFilter: (state: IProductExport): boolean =>
    (state.searchFilters &&
      (state.searchFilters.filters.length > 0 ||
        state.searchFilters.query.length > 0)) ||
    false,
}

export const actions = {
  setCurrentExportFile(
    { commit }: { commit: Commit },
    exportFile: File | null
  ) {
    commit('SET_EXPORT_FILE', exportFile)
  },
  setSearchFile(
    { commit }: { commit: Commit },
    searchFilters: TSearchFilters | null
  ) {
    commit('SET_SEARCH_FILE', searchFilters)
  },
  triggerRecomputeProduct({ commit }: { commit: Commit }) {
    commit('TRIGGER_RECOMPUTE_PRODUCT')
  },
  setExportSettings(
    { commit }: { commit: Commit },
    payload: {
      key: keyof TExportSettings
      value: unknown
    }
  ) {
    commit('ADD_TO_EXPORT_SETTINGS', payload)
  },
  setNotificationOpened(
    { commit }: { commit: Commit },
    notificationOpened: boolean
  ) {
    commit('SET_NOTIFICATION_OPENED', notificationOpened)
  },
}
