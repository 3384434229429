<template>
  <div v-if="isReady" class="instance-picker">
    <Multiselect
      v-model="instance"
      :options="availableClients"
      value-prop="name"
      :searchable="true"
      :max-height="300"
      :placeholder="$t('global.Type_to_search')"
      :can-deselect="false"
      track-by="name"
      label="name"
      class="pointer instance-picker-multiselect"
      open-direction="top"
      :can-clear="false"
      @open="scrollToSelected"
    >
      <template #noResult> - </template>
      <template #option="{ option }">
        <div class="uk-flex uk-flex-middle">
          <div class="option__title">
            <span class="portal-name uk-margin-small-right">{{
              option.name
            }}</span>
            <br />
            <span class="uk-text-small uk-text-muted">{{
              `(${option.status})`
            }}</span>
          </div>
        </div>
      </template>
      <template #singlelabel="{ value }">
        <div class="option__title">
          <span class="portal-name uk-margin-small-right">{{
            value.name
          }}</span>
          <br />
          <span class="uk-text-small uk-text-muted">{{
            `(${value.status})`
          }}</span>
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const availableClients = computed(() => store.state.users.availableClients)
const currentSession = computed(() => store.state.users.currentSession)

const foundInstance = computed(() =>
  availableClients.value.find(
    (instance) => instance.name === currentSession.value
  )
)

const isReady = () => {
  return currentSession.value && availableClients.value.length > 0
}

const chooseNextRoute = (isEditInstance) => {
  let nextRoute
  if (isEditInstance) {
    nextRoute = 'edit-instance'
  } else if (route.name === 'rule-editation') {
    nextRoute = 'rules'
  } else {
    nextRoute = route.name
  }
  return nextRoute
}

const instance = computed({
  get() {
    if (foundInstance.value) return foundInstance.value.name
    else return availableClients.value[0].name
  },
  set(instance) {
    const isEditInstance = route.name === 'edit-instance'
    const nextRoute = chooseNextRoute(isEditInstance)

    store.dispatch('users/selectInstance', {
      instance: instance,
      refresh: !isEditInstance,
      goToEdit: isEditInstance,
      nextRoute,
    })
  },
})

const scrollToSelected = () => {
  window.setTimeout(() => {
    const selectedElement = document.querySelector(
      '.multiselect-option.is-selected'
    )

    const parentElement = document.querySelector('.multiselect-options')
    if (selectedElement && parentElement) {
      parentElement.scrollTop = selectedElement.offsetTop
    }
  }, 50)
}
</script>

<style lang="scss">
@import '../assets/styles/variables.scss';
.instance-picker {
  margin: 0;

  .option__title {
    padding-left: 5px;
    .uk-text-small {
      font-size: 0.75rem;
    }

    .uk-text-muted {
      color: gray !important;
    }
  }

  .multiselect-options {
    bottom: 56px;
    width: 188px;
  }

  .instance-picker-multiselect {
    max-width: 196px;
  }

  .multiselect,
  .multiselect-wrapper,
  .multiselect__single {
    font-size: 12px;
  }

  .multiselect-wrapper {
    min-height: 58px !important;
    background-color: #e2e8f0 !important;
    justify-content: space-between;
  }

  .multiselect-search {
    width: 100%;
    border: 0;
    padding: 8px 35px 8px 14px;
    outline: none;
    background: transparent;
    font-size: 16px;
    font-family: inherit;
    position: absolute;
    input {
      padding-left: 5px !important;
      font-size: 13px !important;
    }
    &:focus {
      outline: none !important;
      outline-color: transparent !important;
    }
  }
  .multiselect-option {
    color: #35495e !important;
    padding-top: 9px;
    padding-right: 12px;
    padding-bottom: 9px;
    padding-left: 12px;
    &.is-selected {
      color: #fff !important;
      background: #46ade5 !important;
      .uk-text-muted {
        color: #fff !important;
      }
    }
  }

  .multiselect-option.is-pointed,
  .multiselect-option.is-selected.is-pointed {
    color: #fff !important;
    background: #039be5 !important;
    .uk-text-muted {
      color: #fff !important;
    }
  }

  .multiselect-dropdown {
    width: 188px;

    &.is-top {
      border-radius: 0 0 0 0;
    }
  }

  .multiselect__placeholder {
    padding-left: 5px;
  }

  .multiselect-caret {
    margin-left: auto;
  }
}
</style>
