<template>
  <input v-if="tag === 'input'" v-model="model" @keyup="emitRealTime" />
  <select v-else-if="tag === 'select'" v-model="model">
    <slot />
  </select>
  <textarea v-else-if="tag === 'textarea'" v-model="model" />
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    tag: {
      type: String,
      default: 'input',
    },
    delay: {
      type: Number,
      default: 500,
    },
    value: {
      type: String,
      default: '',
    },
    clearIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: this.value,
      unwatch: undefined,
    }
  },
  watch: {
    value(value) {
      this.model = value
    },
    delay() {
      this.watch()
    },
  },
  mounted() {
    this.watch()
  },
  methods: {
    watch() {
      if (this.unwatch) this.unwatch()

      this.unwatch = this.$watch(
        'model',
        debounce((v) => this.$emit('input-change', v), this.delay)
      )
    },
    emitRealTime() {
      this.$emit('real-time-input', this.model)
    },
  },
}
</script>
