import ApiFetcher from '@/services/ApiFetcher'

export default class Statistics extends ApiFetcher {
  static async getFilterParameters() {
    try {
      const response = await ApiFetcher.ApiV1Call.get(
        '/pairing_stats/filter_parameters'
      )
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getFilteredStatistics(data) {
    try {
      const response = await ApiFetcher.ApiV1Call.post('/pairing_stats', data)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getStatisticsProducts(data) {
    try {
      const response = await ApiFetcher.ApiV1Call.post(
        '/pairing_stats/generate_token',
        data
      )
      window.open(
        `${ApiFetcher.Host}/#search//?products_token=${response.data.products_token}`
      )
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async exportProducts(data) {
    try {
      const response = await ApiFetcher.ApiV1Call.post(
        '/pairing_stats/export_changes',
        data
      )
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
