import UiKit from 'uikit'
export const flashMessages = {
  methods: {
    flashSuccess(
      translationKey,
      variable = null,
      count = null,
      close = true,
      timeout = 5000
    ) {
      if (close) UiKit.notification.closeAll()
      if (count) {
        UiKit.notification(window.i18n.t(translationKey, count), {
          status: 'success',
          pos: 'top-center',
          timeout: timeout,
        })
      } else {
        UiKit.notification(window.i18n.t(translationKey, variable), {
          status: 'success',
          pos: 'top-center',
          timeout: timeout,
        })
      }
    },

    flashWarning(translationKey, count = 1) {
      UiKit.notification(window.i18n.t(translationKey, count), {
        status: 'warning',
        pos: 'top-center',
      })
    },

    flashError(translationKey, count = 1, data = null) {
      UiKit.notification(window.i18n.t(translationKey, count, data), {
        status: 'danger',
        pos: 'top-center',
      })
    },
    alert(translationKey) {
      UiKit.modal.alert(window.i18n.t(translationKey))
    },
  },
}
