import Rules from '@/services/Rules.js'
import Router from '@/router.js'

export const namespaced = true

export const state = {
  loadingState: {
    loadingRules: true,
    loadingRule: true,
  },
  rules: [],
  rulesPage: 1,
  rulesPerPageLimit: 10,
  ruleFilterOptions: [],
  ruleFilterAllOptions: [],
  rule: {
    name: null,
    active: null,
    note: null,
    created_at: null,
    final: null,
    id: null,
    global: false,
    expirationTime: null,
    expirationUnit: 's',
    configs: [],
    logicalOperator: null,
    approvalSystem: null,
    position: null,
    filters: [],
  },
  ruleConfigOptions: {},
  categories: [],
  stores: [],
  configsCount: 0,
  products: [],
  productsCount: 0,
  availableClients: [],
  rulesHistory: [],
  selectedVersion: null,
  selectedVersionRule: null,
  selectedVersionRules: [],
  selectedVersionDeletedRules: [],
  customSubstitutionOptions: [],
  productsLoading: false,
  nasoskaDefaultConfiguration: {},
  showDuplicateRuleModal: false,
}

export const getters = {
  loadingRules(state) {
    return state.loadingState.loadingRules
  },
  loadingRule(state) {
    return state.loadingState.loadingRule
  },
}

export const mutations = {
  SET_RULES(state, rules) {
    state.rules = rules
  },
  SET_EXPIRATION_UNIT(state, unit) {
    state.rule.expirationUnit = unit
  },
  SET_RULE(state, rule) {
    state.rule = rule
  },
  SET_RULE_FILTER_OPTIONS(state, options) {
    state.ruleFilterOptions = options.available_parameters || []
    state.ruleFilterAllOptions = options.all_parameters || []
  },
  SET_RULE_AVAILABLE_STORES(state, data) {
    state.stores = data
  },
  SET_CUSTOM_SUBSTITUTION_OPTIONS(state, options) {
    state.customSubstitutionOptions = options
  },
  SET_AVAILABLE_CLIENTS(state, clients) {
    state.availableClients = clients
  },
  SET_RULE_PRODUCTS(state, products) {
    state.products = products
  },
  SET_RULE_PRODUCTS_COUNT(state, productCount) {
    state.productsCount = productCount
  },
  CLEAR_RULE_PRODUCTS(state) {
    state.productsCount = 0
    state.products = []
  },
  SET_LOADING_PRODUCTS(state, data) {
    state.productsLoading = data
  },
  SET_LOADING_RULES(state, data) {
    state.loadingState.loadingRules = data
  },
  SET_LOADING_RULE(state, data) {
    state.loadingState.loadingRule = data
  },
  SET_RULE_CONFIG_OPTIONS(state, options) {
    state.ruleConfigOptions = options
  },
  SET_CONFIGS_COUNT(state, configsCount) {
    state.configsCount = configsCount
  },
  SET_CONFIGS(state, configs) {
    state.rule.configs = configs
  },

  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },

  SET_EXPIRATION_TIME(state, expirationTime) {
    state.rule.configs.azor.find((config) => {
      return config.action === 'expiration'
    }).value = expirationTime
  },

  SET_PORTAL_PAIRING(state, portalPairing) {
    state.rule.portalPairing = portalPairing
  },

  SET_PRICE_FILTER(state, priceFilter) {
    state.rule.priceFilter = priceFilter
  },

  SET_SELECTED_CATEGORIES(state, selectedCategories) {
    state.rule.filters.find((filter) => {
      return filter.parameter_name === 'category'
    }).value = selectedCategories
  },

  SET_RULES_HISTORY(state, history) {
    state.rulesHistory = history
  },

  SET_SELECTED_VERSION(state, version) {
    state.selectedVersion = version
  },

  SET_SELECTED_VERSION_RULE(state, rule) {
    state.selectedVersionRule = rule
  },

  SET_SELECTED_VERSION_RULES(state, rules) {
    state.selectedVersionRules = rules
  },

  SET_SELECTED_VERSION_DELETED_RULES(state, rules) {
    state.selectedVersionDeletedRules = rules
  },

  SET_NASOSKA_DEFAULT_CONFIGURATION(state, data) {
    state.nasoskaDefaultConfiguration = data
  },

  SET_SHOW_DUPLICATE_RULE_MODAL(state, data) {
    state.showDuplicateRuleModal = data
  },

  SET_LOGICAL_OPERATOR(state, data) {
    state.rule.logicalOperator = data
  },

  SET_RULE_PRODUCT_COUNT_IN_LIST(state, data) {
    state.rules[data.index].productCount = data.productCount
    state.rules[data.index].loadingCount = false
    state.rules[data.index].countLoaded = true
  },
}

export const actions = {
  setShowDuplicateRuleModal({ commit }, payload) {
    commit('SET_SHOW_DUPLICATE_RULE_MODAL', payload)
  },
  getRules({ commit }) {
    Rules.getRules()
      .then((rules) => {
        commit(
          'SET_RULES',
          rules.map((rule) => {
            rule.loadingCount = true
            rule.countLoaded = false
            return rule
          })
        )
        commit('SET_LOADING_RULES', false)
        rules.slice(0, 5).forEach((rule, index) => {
          if (!rule.global)
            Rules.getRuleProductCount(rule.id).then((response) => {
              commit('SET_RULE_PRODUCT_COUNT_IN_LIST', {
                productCount: response.product_count,
                index,
              })
            })
        })
      })
      .finally(() => {
        commit('SET_LOADING_RULES', false)
      })
  },

  addConfiguration({ state, commit }, payload) {
    const rule = state.rule
    rule.configs[payload.configType].push(payload.config)
    commit('SET_RULE', rule)
    commit('SET_CONFIGS_COUNT', state.configsCount + 1)
  },
  removeConfiguration({ state, commit, dispatch }, payload) {
    const rule = state.rule
    rule.configs[payload.configType].splice(payload.configIndex, 1)
    commit('SET_RULE', rule)
    dispatch('decreaseConfigCount')
  },
  decreaseConfigCount({ state, commit }) {
    commit('SET_CONFIGS_COUNT', state.configsCount - 1)
  },
  clearRule({ commit }) {
    commit('SET_RULE', {})
  },
  clearRuleProducts({ commit }) {
    commit('CLEAR_RULE_PRODUCTS')
  },
  prepareNewRule({ commit }) {
    commit('CLEAR_RULE_PRODUCTS')
    commit('SET_CONFIGS_COUNT', 0)
    commit('SET_RULE', {
      name: '',
      active: true,
      note: '',
      created_at: null,
      id: null,
      configs: {
        azor: [],
        nasoska: [],
        price_filter: [],
        substitution: [],
      },
      logicalOperator: 'conjunction',
      expirationUnit: 's',
      position: 0,
      filters: [],
      updated_at: null,
    })
    commit('SET_LOADING_RULE', false)
  },

  getRuleFilterOptions({ commit, state }) {
    if (state.ruleFilterOptions.length === 0) {
      return new Promise((resolve) => {
        Rules.getRuleFilterOptions().then((options) => {
          commit('SET_RULE_FILTER_OPTIONS', options)
          commit(
            'SET_RULE_AVAILABLE_STORES',
            options.available_stores.map((store) => {
              return { id: store, label: store }
            })
          )
          commit('SET_AVAILABLE_CLIENTS', options.available_clients)
          resolve()
        })
      })
    }
  },

  getRuleConfigOptions({ commit, state }) {
    if (Object.keys(state.ruleConfigOptions).length === 0) {
      return new Promise((resolve) => {
        Rules.getRuleConfigOptions().then((options) => {
          commit('SET_RULE_CONFIG_OPTIONS', options)
          resolve()
        })
      })
    }
  },

  getNasoskaDefaultConfiguration({ commit, state }) {
    if (Object.keys(state.nasoskaDefaultConfiguration).length === 0) {
      return new Promise((resolve) => {
        Rules.getNasoskaDefaultConfiguration().then((response) => {
          commit('SET_NASOSKA_DEFAULT_CONFIGURATION', response.data)
          resolve()
        })
      })
    }
  },

  updateRule({ commit }, rule) {
    commit('SET_RULE', rule)
  },
  getRuleProducts({ commit, dispatch }, rule) {
    commit('SET_LOADING_PRODUCTS', true)
    dispatch('clearRuleProducts')

    if (rule.filters.length === 0 || state.configsCount === 0) {
      commit('SET_LOADING_PRODUCTS', false)
      return
    }
    let valid = true
    for (const config in state.rule.configs) {
      if (Object.prototype.hasOwnProperty.call(state.rule.configs, config)) {
        state.rule.configs[String(config)].forEach((configType) => {
          if (!configType.valid) {
            valid = false
            commit('SET_LOADING_PRODUCTS', false)
          }
        })
      }
    }

    state.rule.filters.forEach((filter) => {
      if (!filter.valid) {
        valid = false
        commit('SET_LOADING_PRODUCTS', false)
      }
    })
    if (!valid) {
      return
    }

    Rules.getRuleProducts(rule).then((data) => {
      commit('SET_RULE_PRODUCTS_COUNT', data.products_count)
      commit('SET_RULE_PRODUCTS', data.products)
      commit('SET_LOADING_PRODUCTS', false)
    })
  },
  /**
   * @param state
   * @param commit
   * @param payload = {payload.filterType, payload.filter}
   */
  addFilter({ state, commit }, filter) {
    const rule = state.rule
    rule.filters.push(filter)
    commit('SET_RULE', rule)
  },
  /**
   * @param state
   * @param commit
   * @param payload = {payload.filterType, payload.filterIndex}
   */
  deleteRuleFilter({ state, commit }, filterIndex) {
    const rule = state.rule
    rule.filters.splice(filterIndex, 1)
    commit('SET_RULE', rule)
  },

  deleteRule({ dispatch }, rule) {
    Rules.deleteRule(rule.id).then(() => {
      dispatch('getRules')
    })
  },

  /**
   * @param state
   * @param commit
   * @param payload = {payload.filterType, payload.filter, payload.filterIndex}
   */
  updateRuleFilter({ state, commit }, payload) {
    const rule = state.rule
    rule.filters[payload.filterIndex] = payload.filter
    commit('SET_RULE', rule)
  },

  exportProducts({ state }, payload) {
    return Rules.exportProducts({
      rule: state.rule,
      applied: payload.applied,
      visibleCols: payload.visibleCols.map((col) => col.name),
    })
  },

  setLoadingRule({ commit }, value) {
    commit('SET_LOADING_RULE', value)
  },

  getRule({ commit, dispatch }, ruleId) {
    commit('SET_LOADING_RULE', true)
    commit('SET_LOADING_PRODUCTS', true)
    commit('SET_RULE_PRODUCTS', [])
    commit('SET_RULE_PRODUCTS_COUNT', 0)
    Rules.getRule(ruleId).then((rule) => {
      const originalRule = JSON.parse(JSON.stringify(rule))
      dispatch('parseRule', rule)

      commit('SET_LOADING_RULE', false)
      if (!rule.global) {
        dispatch('getRuleProducts', originalRule)
      }
    })
  },

  getCategories({ commit }) {
    Rules.getCategories().then((data) => {
      commit('SET_CATEGORIES', data)
    })
  },

  saveUpdatedRule({ state, dispatch }) {
    dispatch('convertUnit', { from: state.rule.expirationUnit, to: 's' }).then(
      () => {
        Rules.saveUpdatedRule(state.rule).then(() => {
          dispatch('getRule', state.rule.id).then(() => {}) // eslint-disable-line @typescript-eslint/no-empty-function
        })
      }
    )
  },

  parseRule({ commit }, rule) {
    rule.filters.map((filter) => {
      const filterOption = state.ruleFilterOptions.find((option) => {
        return option.name === filter.parameter_name
      })

      filter.valid = true
      if (filterOption) {
        filter.actions = filterOption.actions
        filter.type = filterOption.type
      }
      if (
        filter.parameter_name === 'categories' ||
        filter.parameter_name === 'stores' ||
        filter.type === 'float' ||
        filter.parameter_name === 'store_type' ||
        filter.type === 'boolean' ||
        filter.action === 'exists'
      ) {
        return
      }
      filter.options = filter.value.map((value) => {
        return {
          label: value,
          value,
        }
      })
      return filter
    })

    const parsedConfigs = {
      azor: [],
      price_filter: [],
      substitution: [],
      nasoska: [],
    }

    let configsCount = 0
    rule.configs.forEach((config) => {
      const parsedConfig = {
        action: config.action,
        type: config.type,
        value: config.value,
        end_value: config.end_value,
        disablePriceFilter: config.disable_price_filter,
        parameterName: config.parameter_name,
        newParameter: '',
        newParameterName: '',
        inputDisabled: false,
        customInputFocus: false,
        positive: true,
        options: [],
      }
      let configOption

      if (config.type === 'substitution') {
        configOption = state.ruleConfigOptions.substitutions.find((option) => {
          return option.action === config.action
        })
        const substitutionOption = state.ruleConfigOptions[
          'substitutions'
        ].find((option) => {
          return option.action === config.action
        })
        parsedConfig.valuesCount = substitutionOption.values_count
        parsedConfig.newValue = config.modified_value
        if (
          config.new_parameter &&
          state.ruleFilterAllOptions.includes(config.new_parameter)
        ) {
          parsedConfig.newParameter = config.new_parameter
        } else if (
          config.action === 'replace' &&
          !state.ruleFilterAllOptions.includes(config.parameter_name)
        ) {
          parsedConfig.inputDisabled = true
          parsedConfig.parameterName = 'create_custom_parameter'
          parsedConfig.newParameterName = config.parameter_name
          commit(
            'SET_CUSTOM_SUBSTITUTION_OPTIONS',
            state.customSubstitutionOptions.concat(config.parameter_name)
          )
        } else if (config.new_parameter) {
          parsedConfig.newParameter = 'create_custom_parameter'
          if (config.new_parameter.includes('user_fields.')) {
            parsedConfig.newParameterName =
              config.new_parameter.split('user_fields.')[1]
          } else {
            parsedConfig.newParameterName = config.new_parameter
          }
          commit(
            'SET_CUSTOM_SUBSTITUTION_OPTIONS',
            state.customSubstitutionOptions.concat(config.parameter_name)
          )
        } else if (config.action === 'combine') {
          parsedConfig.value = config.value
          parsedConfig.options = config.value.map((config) => {
            return { value: config, label: config }
          })
          if (config.array) {
            parsedConfig.array = true
            parsedConfig.action = 'combine_to_array'
          } else {
            parsedConfig.delimiter = config.delimiter
          }
          // parsedConfig.value = ''
        }
      } else if (config.type === 'nasoska') {
        configOption = state.ruleConfigOptions[config.type][0]
      } else {
        configOption = state.ruleConfigOptions[config.type].find((option) => {
          return option.action === config.action
        })
      }
      if (configOption.values) {
        parsedConfig.actions = configOption.values
      }
      if (parsedConfig.type === 'nasoska') {
        if (config.value === 'true') {
          parsedConfig.value = true
        } else if (config.value === 'false') {
          parsedConfig.value = false
        } else if (typeof config.value === 'string') {
          parsedConfig.value = [parsedConfig.value]
        }
        if (Array.isArray(parsedConfig.value)) {
          parsedConfig.options = parsedConfig.value.map((value) => {
            return { value, label: value }
          })
        }
      }
      if (configOption.type === 'array' && !configOption.values) {
        parsedConfig.options = parsedConfig.value.map((value) => {
          return { value, label: value }
        })
      }
      parsedConfig.valid = true
      if (config.positive !== null) {
        parsedConfig.positive = config.positive
      }
      configsCount += 1
      parsedConfigs[config.type].push(parsedConfig)
    })

    rule.configs = parsedConfigs
    rule.expirationUnit = 's'
    rule.logicalOperator = rule.logical_operator
    commit('SET_CONFIGS_COUNT', configsCount)
    commit('SET_RULE', rule)
  },

  createRule({ state, dispatch }) {
    dispatch('convertUnit', { from: state.rule.expirationUnit, to: 's' }).then(
      () => {
        Rules.createRule(state.rule).then((response) => {
          Router.push({
            name: 'rule-editation',
            params: {
              ruleId: response.id,
            },
          }).then(() => {
            dispatch('getRule', response.id)
          })
        })
      }
    )
  },

  setRoundType({ state, commit }, type) {
    const rule = state.rule
    rule.recipe.round_type = type
    commit('SET_RULE', rule)
  },

  updateProductsPage({ commit }, page) {
    commit('SET_PRODUCTS_PAGE', page)
  },

  updateProductsPerPage({ commit }, page) {
    commit('SET_PRODUCTS_PERPAGE', page)
  },

  changeSearchOrder({ state, commit }, value) {
    const configs = state.rule.configs

    const search_config = configs.azor.find((config) => {
      return config.action === 'search_order'
    })

    search_config.value = value
    commit('SET_CONFIGS', configs)
  },

  convertUnit({ commit, state }, payload) {
    if (state.rule.configs.azor) {
      let expiration = state.rule.configs.azor.find((config) => {
        return config.action === 'expiration'
      })
      if (!expiration) {
        return
      } else {
        expiration = expiration.value
      }
      let converted = expiration

      if (payload.to === 's') {
        if (payload.from === 'h') {
          converted = expiration * 3600
        } else if (payload.from === 'd') {
          converted = expiration * 86400
        }
      } else if (payload.to === 'd') {
        if (payload.from === 'h') {
          converted = expiration / 24
        } else if (payload.from === 's') {
          converted = expiration / 86400
        }
      } else if (payload.to === 'h') {
        if (payload.from === 's') {
          converted = expiration / 3600
        } else if (payload.from === 'd') {
          converted = expiration * 24
        }
      }

      commit('SET_EXPIRATION_UNIT', payload.to)
      commit('SET_EXPIRATION_TIME', converted)
    }
  },

  async getRulesHistory({ commit }) {
    try {
      const history = await Rules.getRulesHistory()
      commit('SET_RULES_HISTORY', history.history)
      return Promise.resolve(history)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  setSelectedVersion({ commit, state }, version) {
    commit('SET_SELECTED_VERSION', version)
    const history = state.rulesHistory.find(
      (history) => history.version === version
    )

    commit('SET_SELECTED_VERSION_RULES', history.changed_rules)
    commit('SET_SELECTED_VERSION_DELETED_RULES', history.deleted_rules)
  },

  setSelectedVersionRule({ commit, dispatch, state }, data) {
    commit('SET_LOADING_RULE', true)
    let rule = state.selectedVersionRules.find((rule) => rule.id == data.ruleId)
    if (!rule) {
      rule = state.selectedVersionDeletedRules.find(
        (rule) => rule.id == data.ruleId
      )
    }

    dispatch('parseRule', rule)
    commit('SET_LOADING_RULE', false)
  },

  cleanSelectedHistoryVersion({ commit }) {
    commit('SET_SELECTED_VERSION', null)
  },

  // eslint-disable-next-line no-empty-pattern
  async restoreRules({}, version) {
    try {
      const response = await Rules.restoreRules(version)
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  updateRulesByPositioning({ commit }, list) {
    list.forEach((rule, index) => {
      rule.position = index
    })
    commit('SET_RULES', list)
    return Promise.resolve(list)
  },

  async saveRulePositions({ state }) {
    try {
      const response = await Rules.updateRulesPositions({
        positions: state.rules.map((rule) => {
          return {
            id: rule.id,
            position: rule.position,
          }
        }),
      })

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },
}
