export default {
  shortDateTime: (value) =>
    value ? window.moment(value).format('DD.MM. HH:mm') : '',
  yearDateTime: (value) =>
    value ? window.moment(value).format('DD.MM.YYYY HH:mm') : '',
  shortDate: (value) => (value ? window.moment(value).format('DD.MM.') : ''),
  date: (value) => (value ? window.moment(value).format('DD.MM.YYYY') : ''),
  localeNumber: (value) => (value ? value.toLocaleString('cs') : Number(value)),
  upperCase: (value) => (value ? value.toUpperCase() : ''),
  lowerCase: (value) => (value ? value.toLowerCase() : ''),
  pctRound: (value) => Math.round(value),
  localePrice: (value) => {
    // let currency = 'CZK'

    return Number(value).toFixed(2)
    // return value
    //   ? Number(value).toLocaleString('cs', {
    //       style: 'currency',
    //       currency
    //     })
    //   : Number(value).toFixed(2)
  },
  capitalizeLetters: (value) => {
    if (value) {
      const splitStr = value.toLowerCase().split(' ')
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[Number(i)] =
          splitStr[Number(i)].charAt(0).toUpperCase() +
          splitStr[Number(i)].substring(1)
      }
      return splitStr.join(' ')
    } else {
      return ''
    }
  },
}
