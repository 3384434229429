import Files from '@/services/Files.js'

export const namespaced = true

export const state = {
  exports: {
    page: 1,
    files: [],
  },
  imports: {
    page: 1,
    files: [],
  },
  manualImportUrls: [],
}

export const mutations = {
  SET_IMPORT_PAGE(state, data) {
    state.imports.page = data
  },
  SET_EXPORT_PAGE(state, data) {
    state.exports.page = data
  },
  SET_EXPORT_FILES(state, data) {
    state.exports.files = data
  },
  SET_IMPORT_FILES(state, data) {
    state.imports.files = data
  },
  SET_MANUAL_IMPORT_URLS(state, data) {
    state.manualImportUrls = data
  },
}

export const actions = {
  async getFiles({ commit }, payload) {
    try {
      const response = await Files.getFiles(payload)

      if (response) {
        commit(`SET_${payload.toUpperCase()}_FILES`, response.files)
      }

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getImportUrlsProgess({ commit }) {
    try {
      const response = await Files.getImportUrlsProgess()

      if (response && response.data && response.data.progress) {
        commit('SET_MANUAL_IMPORT_URLS', response.data.progress)
      }
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },
}
