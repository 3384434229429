import ApiFetcher from '@/services/ApiFetcher'

export default class ApprovalSystem extends ApiFetcher {
  static async getProducts(search) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.get(
        '/approval_system/?',
        {
          params: {
            search: search.search,
            filter: search.feeder ? '' : search.filter,
            categories: search.categories.join(','),
            shops: search.shops.join(','),
            tags: search.tags.join(','),
            feeder: search.feeder,
            page: search.page,
          },
        }
      )
      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getProductCount(search) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.get(
        '/approval_system/product_count',
        {
          params: {
            search: search.search,
            filter: search.feeder ? '' : search.filter,
            categories: search.categories.join(','),
            shops: search.shops.join(','),
            tags: search.tags.join(','),
            feeder: search.feeder,
            page: search.page,
          },
        }
      )
      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async approve(product) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.post(
        '/approval_system/approve',
        {},
        {
          params: {
            id: product.id,
            nasoska_id: product.nasoska_id,
          },
        }
      )
      if (response.data === true) {
        return Promise.resolve(response)
      } else {
        return Promise.reject(response)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async blacklist(product) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.post(
        '/approval_system/blacklist',
        {},
        {
          params: {
            id: product.id,
            nasoska_id: product.nasoska_id,
          },
        }
      )
      if (response.data === true) {
        return Promise.resolve(response)
      } else {
        return Promise.reject(response)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getParameters() {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.get(
        '/approval_system/parameters'
      )
      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async productExists(product, source = product.source) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.post(
        '/approval_system/product_exists',
        {
          product_id: product.id,
          exists: false,
          source: source,
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async addNewOffer(product) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.get(
        `/products/${product.id}/set_manual_url`,
        {
          params: {
            shop: product.shop,
            url: product.url,
          },
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async bulkApprove(product) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.post(
        '/approval_system/bulk_approve',
        {
          product_id: product.id,
          ids: product.temp_results
            .filter((result) => {
              return result.result_type === 'candidate_result'
            })
            .map((tmpProduct) => tmpProduct.id),
          sources: Array.from(
            new Set(product.temp_results.map((tmpProduct) => tmpProduct.source))
          ),
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async bulkApprovePortal(product, portal) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.post(
        '/approval_system/bulk_approve',
        {
          product_id: product.id,
          ids: product.portals[String(portal)]
            .filter((result) => {
              return result.result_type === 'candidate_result'
            })
            .map((tmpProduct) => tmpProduct.id),
          sources: [portal],
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async bulkBlacklistPortal(product, portal) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.post(
        '/approval_system/bulk_blacklist',
        {
          ids: product.temp_results
            .filter((res) => res.source === portal)
            .map((tmpProduct) => tmpProduct.id),
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async bulkBlacklist(productIds) {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.post(
        '/approval_system/bulk_blacklist',
        {
          ids: productIds,
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async exportProducts() {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.get(
        '/approval_system/unpaired'
      )

      if (response.status == 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `unpaired_stats.xlsx`)
        document.body.appendChild(link)
        link.click()
      }

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
