import type { TProductsTag, TExportConfigData } from '@/types/api/product'
import type { TStoreImportConfig } from '@/types/store/importModal'
import type { Commit } from 'vuex'
import UIkit from 'uikit'

export const namespaced = true

enum ImportState {
  new = 'new',
  success = 'success',
  error = 'error',
}

export type TImportModalState = {
  importState: ImportState
  tag: TProductsTag | Record<string, never>
  previewTableData: Array<Array<string | null>> | []
  importsConfig: TExportConfigData | Record<string, never>
  userFields: Array<string> | undefined
  selectColumnList: Array<string>
  settings: TSettings
  loading: boolean
}

export type TSettings = {
  header: boolean
  deleteOld: boolean
}

type TColumnUpdateParameter = {
  index: number
  value: string
}

const DEFAULT_SETTINGS = {
  header: true,
  deleteOld: false,
} as TSettings

export const state: TImportModalState = {
  importState: ImportState.new,
  tag: {},
  previewTableData: [],
  importsConfig: {},
  userFields: [],
  selectColumnList: [],
  settings: DEFAULT_SETTINGS,
  loading: false,
}

export const mutations = {
  SET_TAG(state: TImportModalState, data: TProductsTag) {
    state.tag = data
  },

  SET_PREVIEW_TABLE_DATA(
    state: TImportModalState,
    data: Array<Array<string | null>>
  ) {
    state.previewTableData = data
  },

  SET_IMPORTS_CONFIG(state: TImportModalState, data: TExportConfigData) {
    state.importsConfig = data
  },

  SET_USER_FIELDS(state: TImportModalState, data: Array<string>) {
    state.userFields = data
  },

  SET_SELECTED_COLUMN_LIST(
    state: TImportModalState,
    previewTableDataRow: Array<string>
  ) {
    state.selectColumnList = new Array(previewTableDataRow.length).fill('')
  },

  UPDATE_SELECTED_COLUMN_LIST(
    state: TImportModalState,
    column: TColumnUpdateParameter
  ) {
    state.selectColumnList[column.index] = column.value
  },

  CLEAR_PREVIEW_TABLE(state: TImportModalState) {
    state.previewTableData = []
  },

  SET_HEADER(state: TImportModalState, value: boolean) {
    state.settings.header = value
  },

  SET_OLD_PRODUCTS(state: TImportModalState, value: boolean) {
    state.settings.deleteOld = value
  },

  CLEAR_SETTINGS(state: TImportModalState) {
    state.settings = DEFAULT_SETTINGS
  },

  SET_LOADING(state: TImportModalState, value: boolean) {
    state.loading = value
  },

  IMPORT_IS_SUCCESSFUL(state: TImportModalState) {
    state.importState = ImportState.success
  },

  IMPORT_IS_NEW(state: TImportModalState) {
    state.importState = ImportState.new
  },

  IMPORT_IS_ERROR(state: TImportModalState) {
    state.importState = ImportState.error
  },
}

export const actions = {
  setImportPreviewSettings(
    { commit }: { commit: Commit },
    {
      previewTableData,
      importsConfig,
    }: {
      previewTableData: TImportModalState['previewTableData']
      importsConfig: TImportModalState['importsConfig']
    }
  ) {
    commit('SET_PREVIEW_TABLE_DATA', previewTableData)
    commit('SET_SELECTED_COLUMN_LIST', previewTableData[0])
    commit('SET_IMPORTS_CONFIG', importsConfig)
    commit('SET_USER_FIELDS', importsConfig.user_fields)
  },

  cleanModal({ commit }: { commit: Commit }) {
    UIkit.modal('#import-modal').hide()
    commit('CLEAR_SETTINGS')
    commit('SET_PREVIEW_TABLE_DATA', [])
    commit('SET_SELECTED_COLUMN_LIST', [])
    commit('SET_IMPORTS_CONFIG', {})
    commit('SET_USER_FIELDS', [])
    commit('IMPORT_IS_NEW', [])
  },
}

export const getters = {
  canImport: (state: TImportModalState) => {
    return state.selectColumnList.length
      ? !state.selectColumnList.includes('')
      : false
  },
  importSettings: (state: TImportModalState): TStoreImportConfig => {
    return {
      fields: state.selectColumnList,
      csvHeader: state.settings.header,
      deleteOld: state.settings.deleteOld,
      userFields: state.userFields,
      filePath: state.importsConfig.file_path,
    }
  },
}
