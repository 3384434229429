import axios from 'axios/index'
import { flashMessages } from '@/mixins/flashMessages'
import store from '@/store/store'

axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
const cancelSource = axios.CancelToken.source()

export default class ApiFetcher {
  static ApiV1Url = process.env.VUE_APP_API_V1_URL
  static Host = process.env.VUE_APP_HOST
  static ApiV2Url = process.env.VUE_APP_API_V2_URL
  static ApiV3Url = process.env.VUE_APP_API_V3_URL
  static ApiUrl = process.env.VUE_APP_API_URL
  static ApiFrontendUrl = process.env.VUE_APP_API_FRONTEND_URL

  static headers = () => {
    const headers = {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest'
    }
    if (process.env.VUE_APP_MODE == 'development') {
      headers['X-Client-Id'] = sessionStorage.getItem('azor_instance_id')
    }
    return headers
  }

  static ApiV1Call = axios.create({
    baseURL: ApiFetcher.ApiV1Url,
    withCredentials: true,
    headers: ApiFetcher.headers(),
  })

  static ApiV1Fetch = (params) => {
    return fetch(`${ApiFetcher.ApiV1Url}${params.url}`, {
      method: 'POST',
      headers: ApiFetcher.headers(),
      credentials: 'include',
      body: JSON.stringify(params.body),
    })
  }

  static buildQueryString = (params) => {
    return Object.keys(params)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      )
      .join('&')
  }

  static connections() {
    const Devise = axios.create({
      baseURL: ApiFetcher.Host,
      withCredentials: true,
      headers: ApiFetcher.headers(),
    })

    const ApiV1Call = axios.create({
      baseURL: ApiFetcher.ApiV1Url,
      withCredentials: true,
      headers: ApiFetcher.headers(),
    })

    const ApiV2Call = axios.create({
      baseURL: ApiFetcher.ApiV2Url,
      withCredentials: true,
      headers: ApiFetcher.headers(),
    })

    const ApiaryCall = axios.create({
      baseURL: 'https://private-3ea5e-approvalsystem1.apiary-mock.com/',
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Credentials': true,
      },
    })

    const ApiV3Call = axios.create({
      baseURL: ApiFetcher.ApiV3Url,
      withCredentials: true,
      headers: ApiFetcher.headers(),
    })

    const ApiCall = axios.create({
      baseURL: `${ApiFetcher.Host}/api`,
      withCredentials: true,
      headers: ApiFetcher.headers(),
    })

    const ApiFrontendCall = axios.create({
      baseURL: `${ApiFetcher.ApiFrontendUrl}`,
      withCredentials: true,
      headers: ApiFetcher.headers(),
    })

    const PageCall = axios.create({
      baseURL: `${ApiFetcher.Host}`,
      withCredentials: true,
      headers: ApiFetcher.headers(),
    })

    const errorRequestHandler = (error) => {
      if (error.status && error.status == 500) {
        flashMessages.methods.flashError('global.error_500_title')
      }

      if (error.response && error.response.status == 500) {
        flashMessages.methods.flashError('global.error_500_title')
      }

      if (error.response && error.response.status == 404) {
        // @todo krome 404ek z /logged
        flashMessages.methods.flashError('global.error_404_title')
      }

      return Promise.reject(error)
    }

    const requestHandler = (request) => {
      request.cancelToken = cancelSource.token
      if (request.withoutClient) return request
      if (typeof request.params !== 'undefined' && !request.params.client_id) {
        if (store && store.state) {
          request.params.client_id = store.state.users.currentSession
        }
      } else if (typeof request.params === 'undefined') {
        if (store && store.state) {
          request.params = { client_id: store.state.users.currentSession }
        }
      }
      return request
    }

    const successResponseHandler = (response) => {
      if (response.status === 401) {
        alert('You are not authorized')
      }

      if (response.status === 404) {
        alert('Status 404')
      }

      return response
    }

    const errorResponseHandler = (error) => {
      if (error.response.data === 'Invalid username or password.')
        return  error.response// Hotfix, needs to be reimplemented.
      if (
        (error.response && error.response.status === 500) ||
        (error.status && error.status === 500) ||
        error === 'Request failed with status code 500'
      ) {
        if (error.response.config.method.toLowerCase() === 'get') {
          flashMessages.methods.flashError('global.error_500_get_title')
        } else if (
          error.response.config.method.toLowerCase() === 'post' ||
          error.response.config.method.toLowerCase() === 'put' ||
          error.response.config.method.toLowerCase() === 'patch'
        ) {
          flashMessages.methods.flashError('global.error_500_post_title')
        } else {
          flashMessages.methods.flashError('global.error_500_title')
        }
        // Router.push({ name: 'errorPage' })
      }

      if (
        (error.response && error.response.status == 404) ||
        (error.status && error.status == 404)
      ) {
        if (error.config.url == '/permissions')
          return
        if (
          error.response.config.url !== '/users' &&
          error.response.config.method === 'get'
        ) {
          flashMessages.methods.flashError('global.error_404_title')
        }
      }

      if (
        (error.response && error.response.status == 401) ||
        (error.status && error.status == 401)
      ) {
        flashMessages.methods.flashError('global.error_401_title')
      }

      if (error.response && error.response.status === 422) {
        flashMessages.methods.flashError('global.error_422_title', 1, {
          message:
            error.response?.data?.error_messages || error.response?.data?.error,
        })
      }

      if (error.response && error.response.data) {
        return Promise.reject(error.response.data)
      }
      return error
    }

    Devise.interceptors.request.use(
      (request) => requestHandler(request),
      (error) => errorRequestHandler(error)
    )

    Devise.interceptors.response.use(
      (response) => successResponseHandler(response),
      (error) => errorResponseHandler(error)
    )

    ApiCall.interceptors.request.use(
      (request) => requestHandler(request),
      (error) => errorRequestHandler(error)
    )

    ApiCall.interceptors.response.use(
      (response) => successResponseHandler(response),
      (error) => errorResponseHandler(error)
    )

    ApiFrontendCall.interceptors.request.use(
      (request) => requestHandler(request),
      (error) => errorRequestHandler(error)
    )

    ApiFrontendCall.interceptors.response.use(
      (response) => successResponseHandler(response),
      (error) => errorResponseHandler(error)
    )

    PageCall.interceptors.request.use(
      (request) => requestHandler(request),
      (error) => errorRequestHandler(error)
    )

    PageCall.interceptors.response.use(
      (response) => successResponseHandler(response),
      (error) => errorResponseHandler(error)
    )

    ApiV1Call.interceptors.request.use(
      (request) => requestHandler(request),
      (error) => errorRequestHandler(error)
    )

    ApiV1Call.interceptors.response.use(
      (response) => successResponseHandler(response),
      (error) => errorResponseHandler(error)
    )

    ApiV2Call.interceptors.request.use(
      (request) => requestHandler(request),
      (error) => errorRequestHandler(error)
    )

    ApiV2Call.interceptors.response.use(
      (response) => successResponseHandler(response),
      (error) => errorResponseHandler(error)
    )

    ApiV3Call.interceptors.request.use(
      (request) => requestHandler(request),
      (error) => errorRequestHandler(error)
    )

    ApiV3Call.interceptors.response.use(
      (response) => successResponseHandler(response),
      (error) => errorResponseHandler(error)
    )

    return {
      PageCall: PageCall,
      Devise: Devise,
      ApiV1Call: ApiV1Call,
      ApiV2Call: ApiV2Call,
      ApiaryCall: ApiaryCall,
      ApiV3Call: ApiV3Call,
      ApiCall: ApiCall,
      ApiFrontendCall: ApiFrontendCall,
      cancelSource: cancelSource,
    }
  }
}
