import ApiFetcher from '@/services/ApiFetcher'

export default class Instances extends ApiFetcher {
  static async getInstances() {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.get('/clients')

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getInstance(instanceId) {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.get(
        `/clients/${instanceId}`
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async createInstance(instance) {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.post(
        '/clients',
        this.normalizeInstance(instance)
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async updateInstance(instance) {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.put(
        `/clients/${instance.id}`,
        this.normalizeInstance(instance)
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getStores() {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.get(`/stores`)

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async importHeurekaNow(instanceName) {
    try {
      const response = await ApiFetcher.connections().ApiCall.get(
        `/clients/heureka_now`,
        {
          params: {
            client_id: instanceName,
          },
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getFeedInfo(instanceId) {
    try {
      const response = await ApiFetcher.connections().ApiCall.get(
        `/feed_info`,
        {
          params: {
            client_id: instanceId,
          },
        }
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async deleteInstance(instanceId) {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.delete(
        `/clients/${instanceId}`
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static normalizeInstance(instance) {
    const storeIds = instance.store_ids.concat(instance.portal_ids)
    return {
      name: instance.name,
      type: instance.type,
      limit_product_count: instance.limit_product_count,
      invoicing_id: instance.invoicing_id,
      pbi_history: instance.pbi_history,
      default_expiration: instance.default_expiration,
      feed_import: JSON.parse(instance.feed_import),
      feature_flags: JSON.parse(instance.feature_flags),
      store_ids: storeIds,
      pbi_settings: instance.pbi_settings,
    }
  }

  static async duplicateInstance(params) {
    try {
      const response = await ApiFetcher.connections().ApiV3Call.post(
        `/clients/duplicate`,
        params
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
