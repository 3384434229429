<template>
  <ul class="uk-breadcrumb uk-margin-bottom uk-margin-remove-top">
    <li v-for="(route, index) in routes" :key="route">
      <span v-if="last(index)">
        {{ $t(`navigation.${translatedRoute(route)}`) }}
      </span>
      <router-link v-else :to="{ name: route }">
        {{ $t(`navigation.${translatedRoute(route)}`) }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    translatedRoute(route) {
      return route.replace(/-/g, '_')
    },
    last(index) {
      return index + 1 === this.routes.length
    },
  },
}
</script>

<style scoped></style>
