import Products from '@/services/Products'
import UIkit from 'uikit'
import { Deserializer } from 'jsonapi-serializer'
import NProgress from 'nprogress'

export const namespaced = true

export const state = {
  products: [],
  filters: {},
  searchQuery: '',
  allShops: [],
  allTags: [],
  allCategories: [],
  allPortals: [],
  allProducers: [],
  pagination: {
    total: 0,
  },
  selectedProduct: {},
  loadingProduct: false,
  allCategoriesMapping: {},
  selectedResultFields: [],
  selectedProductFields: [],
  productFields: [],
  allResults: {},
}

export const mutations = {
  SET_ALL_SHOPS(state, shops) {
    state.allShops = shops
  },

  SET_ALL_PORTALS(state, shops) {
    state.allPortals = shops
  },

  SET_ALL_TAGS(state, tags) {
    state.allTags = tags
  },

  SET_PRODUCTS(state, products) {
    state.products = products
  },

  SET_ALL_CATEGORIES(state, categories) {
    state.allCategories = categories
  },

  SET_ALL_PRODUCERS(state, producers) {
    state.allProducers = producers
  },

  SET_CATEGORIES_MAPPING(state, mapping) {
    state.allCategoriesMapping = mapping
  },

  SET_PAGING(state, paging) {
    state.pagination = paging
  },

  SET_SELECTED_PRODUCT(state, product) {
    state.selectedProduct = product
  },

  SET_LOADING_PRODUCT(state, data) {
    state.loadingProduct = data
  },
  SET_PAIRED_SOURCES(state, data) {
    state.selectedProduct.pairedSources = data
  },
  SET_UNPAIRED_SOURCES(state, data) {
    state.selectedProduct.unpairedSources = data
  },
  SET_PRODUCT_FIELDS(state, data) {
    state.productFields = data
  },
  SET_ALL_RESULTS(state, data) {
    state.allResults = data
  },
}

export const getters = {
  defaultChartOptions: (state, getters, rootState) => {
    return {
      chart: {
        type: 'line',
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: 'Poppins, sans-serif',
      },
      locales: [
        {
          name: 'cs',
          options: {
            months: [
              'leden',
              'únor',
              'březen',
              'duben',
              'květen',
              'červen',
              'červenec',
              'srpen',
              'září',
              'říjen',
              'listopad',
              'prosinec',
            ],
            shortMonths: [
              'led.',
              'úno.',
              'bře.',
              'dub.',
              'kvě.',
              'čvn.',
              'čvc.',
              'srp.',
              'zář.',
              'říj.',
              'lis.',
              'pro.',
            ],
            days: [
              'neděle',
              'pondělí',
              'úterý',
              'středa',
              'čtvrtek',
              'pátek',
              'sobota',
            ],
            shortDays: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
          },
        },
        {
          name: 'en',
          options: {
            months: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            shortMonths: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            days: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          },
        },
      ],
      colors: rootState.config.apex.colors,
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     // shade: 'dark',
      //     type: 'vertical',
      //     shadeIntensity: 1,
      //     gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      //     inverseColors: false,
      //     opacityFrom: 0.4,
      //     opacityTo: 0.7,
      //     stops: [0, 90, 100],
      //     colorStops: []
      //   }
      // },
      stroke: {
        show: true,
        curve: 'stepline',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      markers: {
        size: 3,
        colors: rootState.config.apex.colors,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.5,
        fillOpacity: 1,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        hover: {
          size: 6,
          sizeOffset: 2,
        },
      },
      xaxis: {
        // type: 'datetime',
        labels: {
          formatter: function (value, timestamp) {
            if (localStorage.getItem('_azor_locale') === 'cs') {
              window.moment.locale('cs')
              return window.moment(new Date(timestamp)).format('DD. MMM')
            } else {
              window.moment.locale('en')
              return window.moment(new Date(timestamp)).format('DD MMM')
            }
          },
        },
      },
      legend: {
        fontFamily: 'Poppins, sans-serif',
        showForSingleSeries: true,
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          show: true,
          formatter: function (val) {
            // eslint-disable-next-line no-undef
            return chartFormatter(val, rootState.users.user.locale)
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return Number.parseFloat(val).toFixed(2)
        },
      },
    }
  },
  defaultPortalChartOptions: (state, getters, rootState) => {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: 'Poppins, sans-serif',
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '25%',
          distributed: true,
        },
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          show: true,
          formatter: function (val) {
            return Number.parseFloat(val).toFixed(2)
          },
        },
      },
      legend: {
        show: false,
      },
      colors: rootState.config.apex.colors,
    }
  },
}

export const actions = {
  async getParameters({ commit }) {
    try {
      const parameters = await Products.getParameters()

      commit('SET_ALL_SHOPS', parameters.shops)
      commit('SET_ALL_PORTALS', parameters.portals)
      commit('SET_ALL_TAGS', parameters.tags)
      commit('SET_ALL_CATEGORIES', parameters.categories)
      commit('SET_ALL_PRODUCERS', parameters.producers)

      return Promise.resolve(parameters)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getProduct({ commit, rootState }, productId) {
    try {
      commit('SET_LOADING_PRODUCT', true)
      NProgress.start()
      const response = await Products.getProduct(
        productId,
        rootState.users.currentSession
      )
      let product = await new Deserializer({
        keyForAttribute: 'snake_case',
      }).deserialize(response)
      product = product[0]

      UIkit.offcanvas('#product-detail').show()

      if (product) {
        if (product.valid_results) {
          product.valid_results.sort((a, b) => a.price - b.price)
        }
        product.unpairedSources = {}
        product.pairedSources = {}
        commit('SET_SELECTED_PRODUCT', product)
      }
      NProgress.done()
      commit('SET_LOADING_PRODUCT', false)
      return Promise.resolve(product)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  fillSources({ commit }, payload) {
    const tmpSource = payload.sources.reduce((result, current) => {
      result[String(current)] = {
        query: '',
        url: '',
        exact: false,
        options: [],
        suggestions: [],
        editing: false,
        loading: false,
        manual: false,
        badFormat: false,
        findedProduct: [null],
        linkHover: false,
        badUrlFormat: false,
        lastSearch: '',
        waitForRefresh: false,
      }
      return result
    }, {})
    if (payload.type === 'paired') {
      commit('SET_PAIRED_SOURCES', tmpSource)
    } else {
      commit('SET_UNPAIRED_SOURCES', tmpSource)
    }
  },

  setChangedProducts({ commit, state }, payload) {
    payload.shops
      // .filter((shop) => state.selectedProduct.store_names.includes(shop))
      .forEach((source) => {
        if (state.selectedProduct.product_configs) {
          const productConfig = state.selectedProduct.product_configs.find(
            (productConfig) => productConfig.store_name === source
          )
          if (productConfig) {
            if (payload.type === 'paired') {
              const pairedSources = state.selectedProduct.pairedSources
              pairedSources[String(source)].findedProduct = [productConfig.url]
              pairedSources[String(source)].options.push({
                name: productConfig.url,
              })
              pairedSources[String(source)].editing = true
              pairedSources[String(source)].waitForRefresh = true
              pairedSources[String(source)].url = productConfig.url
              commit('SET_PAIRED_SOURCES', pairedSources)
            } else {
              const unpairedSources = state.selectedProduct.unpairedSources
              unpairedSources[String(source)].findedProduct = [
                productConfig.url,
              ]
              unpairedSources[String(source)].options.push({
                name: productConfig.url,
              })
              unpairedSources[String(source)].editing = true
              unpairedSources[String(source)].waitForRefresh = true
              unpairedSources[String(source)].url = productConfig.url
              commit('SET_UNPAIRED_SOURCES', unpairedSources)
            }
          }
        }
      })
  },

  setSuggestions({ commit, state }, payload) {
    payload.shops.forEach(async (shop) => {
      let shops

      if (payload.type === 'paired') {
        shops = state.selectedProduct.pairedSources
      } else {
        shops = state.selectedProduct.unpairedSources
      }

      if (payload.type === 'paired') {
        commit('SET_PAIRED_SOURCES', shops)
      } else {
        commit('SET_UNPAIRED_SOURCES', shops)
      }

      const suggestions = await Products.getSuggestions(
        shop,
        state.selectedProduct.id,
        state.selectedProduct.name
      )
      if (shops[String(shop)].findedProduct) {
        const selectedProduct = suggestions.find(
          (product) => product.url === shops[String(shop)].findedProduct[0]
        )
        if (selectedProduct) {
          shops[String(shop)].findedProduct = [selectedProduct.name]
        }
      }

      shops[String(shop)].suggestions = suggestions
      shops[String(shop)].options = suggestions

      if (payload.type === 'paired') {
        commit('SET_PAIRED_SOURCES', shops)
      } else {
        commit('SET_UNPAIRED_SOURCES', shops)
      }
    })
  },

  async setAllResults({ commit, state }, sources) {
    const results = {}
    for (let i = 0; i < sources.length; ++i) {
      results[sources[Number(i)]] = await Products.getAllResults(
        sources[Number(i)],
        state.selectedProduct.id
      )
    }
    commit('SET_ALL_RESULTS', results)
  },

  clearCurrentProduct({ commit }) {
    commit('SET_SELECTED_PRODUCT', {})
  },

  setProducts({ commit }, products) {
    commit('SET_PRODUCTS', products)
  },

  getProductFields({ commit }) {
    Products.getExportParameters().then((response) => {
      commit('SET_PRODUCT_FIELDS', response.product_fields)
    })
  },

  setProductFields({ commit }, productFields) {
    commit('SET_PRODUCT_FIELDS', productFields)
  },
}
