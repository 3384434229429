export const namespaced = true

export const state = {
  apex: {
    colors: [
      '#3182ce',
      '#805ad5',
      '#5a67d8',
      '#d53f8c',
      '#38a169',
      '#d69e2e',
      '#dd6b20',
      '#e53e3e',
      '#718096',
    ],
    fill: [
      '#3182ce',
      '#805ad5',
      '#5a67d8',
      '#d53f8c',
      '#38a169',
      '#d69e2e',
      '#dd6b20',
      '#e53e3e',
      '#718096',
    ],
  },
}

export const getters = {}

export const mutations = {}

export const actions = {}
