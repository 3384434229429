import ApiFetcher from '@/services/ApiFetcher'

export default class Devise extends ApiFetcher {
  static async login(name: string, password: string, token: string) {
    const response = await ApiFetcher.connections().Devise.post(
      '/users/sign_in',
      {
        authenticity_token: token,
        user: {
          login: name,
          password: password,
        },
      }
    )

    if (
      response.status === 200 ||
      response.data === 'Invalid username or password.'
    ) {
      return response.data
    } else {
      throw new Error('Unhandled response status')
    }
  }

  static async logout() {
    const response = await ApiFetcher.connections().Devise.delete(
      '/users/sign_out'
    )

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Unhandled response status')
    }
  }

  static async login_form() {
    const response = await ApiFetcher.connections().PageCall.get(
      '/users/sign_in'
    )

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Unhandled response status')
    }
  }
}
