import Users from '@/services/Users'
import Devise from '@/services/Devise'
import Router from '@/router'
import { flashMessages } from '@/mixins/flashMessages'

export const namespaced = true

export const state = {
  permissions: [],
  admin: false,
  currentSession: null,
  currentClientId: '',
  locale: 'cs',
  availableClients: [],
  newUpdateAvailable: false,
  currentUser: null,
  users: [],
  all_available_permissions: [],
}

export const mutations = {
  SET_PERMISSIONS(state, data) {
    state.permissions = data
  },
  SET_AVAILABLE_PERMISSIONS(state, data) {
    state.all_available_permissions = data
  },
  SET_ADMIN(state, data) {
    state.admin = data
  },
  SET_CURRENT_SESSION(state, data) {
    state.currentSession = data
  },
  SET_LOCALE(state, data) {
    state.locale = data
  },
  SET_AVAILABLE_CLIENTS(state, data) {
    state.availableClients = data
  },
  SET_UPDATE_AVAILABILITY(state, data) {
    state.newUpdateAvailable = data
  },
  SET_CURRENT_USER(state, data) {
    state.currentUser = data
  },
  SET_USERS(state, data) {
    if (!data) return
    state.users = data.map((user) => {
      user['dirty'] = false
      return user
    })
  },
  SET_DIRTY(state, id, dirty) {
    const user = state.users.find((user) => user.id == id)
    user.dirty = dirty
  },
  CREATE_USER(state, data) {
    data['dirty'] = false
    state.users.push(data)
  },
  UPDATE_USER(state, data) {
    data['dirty'] = false
    state.users = state.users.map((user) => (user.id == data.id ? data : user))
  },
  DELETE_USER(state, id) {
    state.users = state.users.filter((user) => user.id != id)
  },
  SET_CURRENT_CLIENT_ID(state, id) {
    state.currentClientId = id
  },
}

export const actions = {
  setUpdateAvailability({ commit }, value) {
    commit('SET_UPDATE_AVAILABILITY', value)
  },

  async getUserInfo({ commit }) {
    try {
      const response = await Users.getUserInfo()

      commit('SET_PERMISSIONS', response.permissions_list)
      commit('SET_AVAILABLE_PERMISSIONS', response.all_available_permissions)
      commit('SET_ADMIN', response.admin)
      commit(
        'SET_AVAILABLE_CLIENTS',
        response.available_clients.filter((client) => {
          return client.status !== 'archived'
        })
      )
      commit('SET_CURRENT_USER', response.user_name)
      commit('SET_CURRENT_CLIENT_ID', response.current_client_id)
      const locale = localStorage.getItem('_azor_locale')
      if (locale) {
        commit('SET_LOCALE', locale)
      }
      if (!response.admin) {
        localStorage.setItem('_azor_locale', 'cs')
      }
      if (process.env.VUE_APP_MODE === 'development') {
        if (sessionStorage.getItem('azor_instance_id')) {
          commit(
            'SET_CURRENT_SESSION',
            sessionStorage.getItem('azor_instance_id')
          )
        } else if (localStorage.getItem('azor_instance_id')) {
          sessionStorage.setItem(
            'azor_instance_id',
            localStorage.getItem('azor_instance_id')
          )
          commit(
            'SET_CURRENT_SESSION',
            localStorage.getItem('azor_instance_id')
          )
        } else {
          sessionStorage.setItem('azor_instance_id', response.current_client)
          commit('SET_CURRENT_SESSION', response.current_client)
        }
      } else {
        sessionStorage.setItem('azor_instance_id', response.current_client)
        commit('SET_CURRENT_SESSION', response.current_client)
      }
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async logOut() {
    const response = await Devise.logout()
    if (response) {
      const route = Router.resolve({ name: 'logOut' })
      window.location.replace(route.href)
    }
  },

  async getUsers({ commit }) {
    try {
      const response = await Users.getUsers()

      commit('SET_USERS', response.users)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async createUser({ commit }, data) {
    try {
      const response = await Users.createUser(data)
      flashMessages.methods.flashSuccess('users.created_successful')
      commit('CREATE_USER', response.data)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async updateUser({ commit }, data) {
    try {
      const response = await Users.updateUser(data)
      flashMessages.methods.flashSuccess('users.update_successful')
      commit('UPDATE_USER', response.data)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async deleteUser({ commit }, id) {
    try {
      const response = await Users.deleteUser(id)
      flashMessages.methods.flashSuccess('users.deleted_successful')
      commit('DELETE_USER', id)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async selectInstance({ commit, rootState }, payload) {
    try {
      commit('SET_CURRENT_SESSION', payload.instance)
      sessionStorage.setItem('azor_instance_id', payload.instance)

      commit('rules/SET_RULE_FILTER_OPTIONS', [], { root: true })
      commit('rules/SET_RULE_CONFIG_OPTIONS', {}, { root: true })
      await Users.selectInstance(payload.instance)
      if (payload.nextRoute === 'edit-instance') {
        Router.push({
          name: payload.nextRoute,
          params: {
            instanceName: payload.instance,
          },
        })
      } else if (
        rootState.route.name !== 'rules' &&
        payload.nextRoute === 'rules'
      ) {
        Router.push({
          name: payload.nextRoute,
        })
      } else if (
        payload.nextRoute &&
        rootState.route.name &&
        payload.nextRoute === rootState.route.name
      ) {
        window.location = window.location.pathname
      } else if (payload.nextRoute) {
        Router.push({
          name: payload.nextRoute,
        })
      }
    } catch (e) {
      return Promise.reject(e)
    }
  },

  setLanguage({ commit }, language) {
    commit('SET_LOCALE', language)
    localStorage.setItem('_azor_locale', language)
    location.reload()
  },

  setUserAsDirty({ commit }, userId) {
    commit('SET_DIRTY', userId, true)
  },
}
