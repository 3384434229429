import * as config from './modules/config.js'
import * as rules from './modules/rules.js'
import * as approvalSystem from './modules/approvalSystem.js'
import * as statistics from './modules/statistics.js'
import * as products from './modules/products.js'
import * as users from './modules/users.js'
import * as productExport from './modules/productExport.ts'
import * as files from './modules/files.js'
import * as instances from './modules/instances.js'
import * as tables from './modules/tables.ts'
import * as importModal from './modules/importModal.ts'

import { ref, computed } from 'vue'
import { createStore } from 'vuex'

const store = createStore({
  modules: {
    config,
    rules,
    statistics,
    approvalSystem,
    products,
    users,
    productExport,
    files,
    instances,
    tables,
    importModal,
  },
  state: {
    route: null,
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route
    },
  },
  actions: {},
})

const state = ref({
  selectedUserPairing: {},
})

function setUserPairing(userPairing) {
  state.value.selectedUserPairing = userPairing
}

const getUserPairing = computed(() => state.value.selectedUserPairing)

export { setUserPairing, getUserPairing }
export default store
