import ApiFetcher from '@/services/ApiFetcher'
import { Deserializer } from 'jsonapi-serializer'

export default class Products extends ApiFetcher {
  static async getProduct(productId, clientId) {
    const response = await ApiFetcher.connections().ApiV3Call.post(
      `/products`,
      {
        id: productId,
        with_results: true,
        with_product_configs: true,
      },
      {
        params: {
          client_id: clientId,
        },
      }
    )
    return response.data
  }

  static async getParameters() {
    const response = await ApiFetcher.connections().ApiV3Call.get(
      `/products/search_parameters`
    )
    return response.data
  }

  static async getExportParameters() {
    const response = await ApiFetcher.connections().ApiV3Call.get(
      `/exports/parameters`
    )
    return response.data
  }

  static async exportProducts(data) {
    const response = await ApiFetcher.connections().ApiV3Call.post(
      `/exports`,
      data
    )
    return response.data
  }

  static async saveManualUrl(data) {
    const response = await ApiFetcher.connections().ApiV2Call.get(
      `/products/${data.productId}/set_manual_url`,
      {
        params: {
          url: data.url,
          shop: data.shop,
        },
      }
    )
    return response.data
  }

  static async getNasoskaProducts(data) {
    const response = await ApiFetcher.connections().ApiV3Call.post(
      `/nasoska_products`,
      data
    )
    return response.data
  }

  static async getNasoskaTouchedProducts(data) {
    const response = await ApiFetcher.connections().ApiV3Call.post(
      `/nasoska_products/touched`,
      data
    )
    return response.data
  }

  static async getSuggestions(store, productId, name) {
    const response = await Products.getNasoskaTouchedProducts({
      store,
      product_id: productId,
    })

    let suggestions = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(response)

    if (suggestions.length === 0) {
      const response = await Products.getNasoskaProducts({
        stores: [store],
        offset: 0,
        limit: 25,
        query: name,
        exact_query: false,
      })
      suggestions = await new Deserializer({
        keyForAttribute: 'snake_case',
      }).deserialize(response)
    }

    return suggestions
  }

  static async getAllResults(source, productId, store) {
    const response = await ApiFetcher.connections().ApiV3Call.get(
      `/results/all_results`,
      {
        params: { source, product_id: productId, store },
      }
    )

    const results = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(response.data)

    return results
  }

  static async getProductsTags() {
    return await ApiFetcher.connections().ApiCall.get('/products/tags')
  }
}
