import ApiFetcher from '@/services/ApiFetcher'

export default class Files extends ApiFetcher {
  static async getFiles(type) {
    try {
      const response = await ApiFetcher.connections().ApiCall.get('/files', {
        params: {
          type: type,
        },
      })
      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getImportUrlsProgess() {
    try {
      const response = await ApiFetcher.connections().ApiV2Call.get(
        '/products/progress/all'
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async retryExport(token) {
    try {
      const response = await ApiFetcher.connections().ApiCall.get(
        `/files/${token}/again`
      )

      return Promise.resolve(response.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
