import Instances from '@/services/Instances'
import { Deserializer } from 'jsonapi-serializer'
import moment from 'moment'

export const namespaced = true

export const state = {
  instances: [],
  selectedInstance: {
    name: '',
    type: 'poc',
    limit_product_count: null,
    invoicing_id: null,
    pbi_history: null,
    default_expiration: 86400,
    feed_import: null,
    feature_flags: null,
    portal_ids: [],
    store_ids: [],
    pbi_settings: [],
  },
  feeds: [],
  stores: [],
  portals: [],
  feedInfoInterval: null,
}

export const mutations = {
  SET_INSTANCES(state, data) {
    state.instances = data
  },
  SET_SELECTED_INSTANCE(state, data) {
    state.selectedInstance = data
  },
  SET_INSTANCE_TYPE(state, data) {
    state.selectedInstance.type = data
  },
  SET_STORES(state, data) {
    state.stores = data
  },
  SET_PORTALS(state, data) {
    state.portals = data
  },
  SET_INSTANCE_PRODUCT_COUNT(state, data) {
    state.selectedInstance.limit_product_count = data
  },
  SET_INSTANCE_INVOICE_ID(state, data) {
    state.selectedInstance.invoicing_id = data
  },
  SET_INSTANCE_NAME(state, data) {
    state.selectedInstance.name = data
  },
  SET_SELECTED_STORES(state, data) {
    state.selectedInstance.store_ids = data
  },
  SET_SELECTED_PORTALS(state, data) {
    state.selectedInstance.portal_ids = data
  },
  SET_DEFAULT_EXPIRATION(state, data) {
    state.selectedInstance.default_expiration = data
  },
  SET_PBI_HISTORY(state, data) {
    state.selectedInstance.pbi_history = data
  },
  SET_FEEDS(state, data) {
    state.feeds = data
  },
  SET_FEED_INFO_INTERVAL(state, data) {
    state.feedInfoInterval = data
  },
  SET_FEED_IMPORT(state, data) {
    state.selectedInstance.feed_import = data
  },
  SET_FEATURE_FLAGS(state, data) {
    state.selectedInstance.feature_flags = data
  },
}

export const actions = {
  prepareNewInstance({ commit }) {
    const instance = {
      name: '',
      type: 'poc',
      limit_product_count: null,
      invoicing_id: null,
      pbi_history: null,
      default_expiration: 86400,
      feed_import: null,
      feature_flags: null,
      portal_ids: [],
      store_ids: [],
      pbi_settings: [],
    }
    commit('SET_SELECTED_INSTANCE', instance)
  },

  setInstanceType({ commit }, type) {
    commit('SET_INSTANCE_TYPE', type)
  },

  async getInstances({ commit }) {
    try {
      const response = await Instances.getInstances()

      let instances = await new Deserializer({
        keyForAttribute: 'snake_case',
      }).deserialize(response)

      instances = instances.map((instance) => {
        if (instance.type === 'poc' && instance.type_updated_at) {
          const diff = moment().diff(moment(instance.type_updated_at), 'days')
          if (diff > 21)
            instance.status = {
              error: 'instances.poc_time_exceeded',
            }
        }
        return instance
      })
      commit('SET_INSTANCES', instances)

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getInstance({ commit }, instanceId) {
    try {
      const response = await Instances.getInstance(instanceId)

      const instance = await new Deserializer({
        keyForAttribute: (attribute) => {
          return attribute
        },
        transform: (record) => {
          record.stores = record.stores.map((store) => {
            return {
              label: store['name'],
              id: store['id'],
              type: store['type'],
            }
          })
          return record
        },
      }).deserialize(response)

      instance.feed_import = JSON.stringify(instance.feed_import, null, '\t')
      instance.feature_flags = JSON.stringify(
        instance.feature_flags,
        null,
        '\t'
      )

      instance.portal_ids = instance.stores
        .filter((store) => store.type === 'portal')
        .map((store) => store['id'])
      instance.store_ids = instance.stores
        .filter((store) => store.type === 'shop')
        .map((store) => store['id'])

      commit('SET_SELECTED_INSTANCE', instance)

      return Promise.resolve(instance)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getNasoskaStores({ commit }) {
    try {
      const response = await Instances.getStores()

      const stores = await new Deserializer({
        keyForAttribute: 'snake_case',
        transform: (record) => {
          record['label'] = record['name']
          delete record['name']
          return record
        },
      }).deserialize(response)

      commit(
        'SET_STORES',
        stores.filter((store) => store.type === 'shop')
      )
      commit(
        'SET_PORTALS',
        stores.filter((store) => store.type === 'portal')
      )

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async createInstance({ state }) {
    try {
      const response = await Instances.createInstance(state.selectedInstance)

      const instance = await new Deserializer({
        keyForAttribute: 'snake_case',
      }).deserialize(response)

      return Promise.resolve(instance)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getFeedInfo({ state, commit }) {
    try {
      const response = await Instances.getFeedInfo(state.selectedInstance.name)

      if (Array.isArray(response)) {
        commit('SET_FEEDS', response)
      } else {
        commit('SET_FEEDS', [])
      }

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async updateInstance({ state }) {
    try {
      const response = await Instances.updateInstance(state.selectedInstance)

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  clearInstances({ commit }) {
    commit('SET_INSTANCES', [])
  },
}
