import ApprovalSystem from '@/services/ApprovalSystem'
import { flashMessages } from '@/mixins/flashMessages'

export const namespaced = true

export const state = {
  products: [],
  filters: {},
  searchQuery: '',
  allShops: [],
  allTags: [],
  allCategories: [],
  pagination: {
    total: 0,
    page: 1,
  },
  productCount: 0,
  feederProducts: [],
  selectedColumns: [],
}

export const mutations = {
  SET_ALL_SHOPS(state, shops) {
    state.allShops = shops
  },

  SET_ALL_TAGS(state, tags) {
    state.allTags = tags
  },

  SET_PRODUCTS(state, products) {
    state.products = products
  },

  SET_ALL_CATEGORIES(state, categories) {
    state.allCategories = categories
  },

  SET_PAGING(state, paging) {
    state.pagination = paging
  },

  SET_PAGE(state, data) {
    state.pagination.page = data
  },

  SET_SELECTED_COLUMNS(state, data) {
    state.selectedColumns = data
  },
}

export const actions = {
  async getProducts({ dispatch }, search) {
    try {
      const response = await ApprovalSystem.getProducts(search)

      dispatch('parseResponse', response)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getProductCount({ commit }, search) {
    try {
      const response = await ApprovalSystem.getProductCount(search)

      commit('SET_PAGING', {
        page: search.page || 1,
        total: Math.ceil(response.product_count / 25),
      })
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  parseResponse({ commit }, response) {
    response.products.forEach((product) => {
      product.expanded = false
      product.mergedProducts = []
      product.image_expanded = false
      product.defaultImage = false
      product.portals = {}

      const fields = ['temp_results', 'results', 'blacklist_results']

      fields.forEach((field) => {
        product[String(field)].forEach((tempResult) => {
          tempResult.image_expanded = false
          if (product.portals[tempResult.source]) {
            product.portals[tempResult.source].push(tempResult)
          } else {
            product.portals[tempResult.source] = [tempResult]
          }
          product.mergedProducts.push(tempResult)
        })
        // product.mergedProducts = product.mergedProducts.concat(product[field])
      })
    })

    if (response.products.length > 0) {
      response.products[0].expanded = true
    }
    commit('SET_PRODUCTS', response.products)
  },

  async getParameters({ commit }) {
    try {
      const response = await ApprovalSystem.getParameters()

      commit(
        'SET_ALL_SHOPS',
        response.shops.map((shop) => {
          return { id: shop, label: shop }
        })
      )
      commit(
        'SET_ALL_TAGS',
        response.tags.map((tag) => {
          return { id: tag, label: tag }
        })
      )
      commit(
        'SET_ALL_CATEGORIES',
        response.categories.map((category) => {
          return { id: category.name, label: category.name }
        })
      )

      // commit('SET_FIELDS')

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async productExists({ dispatch }, result) {
    try {
      const response = await ApprovalSystem.productExists(result)
      result.shop = result.source

      dispatch('removeShop', result)

      flashMessages.methods.flashSuccess(
        'approval_system.product_marked_as_non_existing',
        { source: result.source }
      )
      return Promise.resolve(response)
    } catch (e) {
      console.log(e)
      flashMessages.methods.flashSuccess(
        'approval_system.failed_to_mark_non_existing'
      )
      return Promise.reject(e)
    }
  },

  async bulkApprovePortal({ dispatch }, payload) {
    try {
      const response = await ApprovalSystem.bulkApprovePortal(
        payload.product,
        payload.portal
      )

      dispatch('removePortal', payload)

      flashMessages.methods.flashSuccess(
        'approval_system.bulk_approve_succesfull'
      )

      return Promise.resolve(response)
    } catch (e) {
      console.log(e)
      flashMessages.methods.flashError(
        'approval_system.bulk_approve_not_succesfull'
      )
      return Promise.reject(e)
    }
  },

  async bulkApprove({ dispatch }, payload) {
    try {
      const response = await ApprovalSystem.bulkApprove(payload.product)

      dispatch('removeProduct', payload.product)

      flashMessages.methods.flashSuccess(
        'approval_system.bulk_approve_succesfull'
      )

      return Promise.resolve(response)
    } catch (e) {
      console.log(e)
      flashMessages.methods.flashError(
        'approval_system.bulk_approve_not_succesfull'
      )
      return Promise.reject(e)
    }
  },

  async bulkBlacklistPortal({ dispatch }, payload) {
    try {
      const response = await ApprovalSystem.bulkBlacklistPortal(
        payload.product,
        payload.portal
      )

      dispatch('removePortal', payload)

      flashMessages.methods.flashSuccess(
        'approval_system.bulk_blacklist_succesfull'
      )

      return Promise.resolve(response)
    } catch (e) {
      console.log(e)
      flashMessages.methods.flashError(
        'approval_system.bulk_blacklist_not_succesfull'
      )
      return Promise.reject(e)
    }
  },

  removeProduct({ commit, state }, product) {
    const index = state.products.indexOf(product)
    commit(
      'SET_PRODUCTS',
      state.products.filter((tmpProduct) => tmpProduct.id !== product.id)
    )
    const products = state.products
    products.forEach((tmpProduct) => {
      tmpProduct.expanded = false
    })
    if (products.length !== 0) {
      if (index === products.length) {
        if (products[index - 1]) {
          products[index - 1].expanded = true
        }
      } else {
        products[Number(index)].expanded = true
      }
    }

    commit('SET_PRODUCTS', products)
  },

  removePortal({ commit, state }, payload) {
    const index = state.products.indexOf(payload.product)

    const tmpProducts = [...state.products]

    if (tmpProducts[Number(index)]) {
      tmpProducts[Number(Number(index))].mergedProducts = tmpProducts[
        Number(index)
      ].mergedProducts.filter((res) => {
        return res.source !== payload.portal
      })

      delete tmpProducts[Number(index)].portals[payload.portal]
      commit('SET_PRODUCTS', tmpProducts)
    }
  },

  removeShop({ commit, state }, product) {
    state.products[product.index].mergedProducts = state.products[
      product.index
    ].mergedProducts.filter((tempResult) => {
      tempResult.source !== product.shop
    })
    delete state.products[product.index].portals[product.shop]
    commit('SET_PRODUCTS', state.products)
  },

  async bulkBlacklist({ dispatch }, payload) {
    try {
      const response = await ApprovalSystem.bulkBlacklist(payload.productIds)

      dispatch('removeProduct', payload.product)

      flashMessages.methods.flashSuccess(
        'approval_system.bulk_blacklist_succesfull'
      )
      return Promise.resolve(response)
    } catch (e) {
      flashMessages.methods.flashError(
        'approval_system.bulk_blacklist_not_succesfull'
      )
      return Promise.reject(e)
    }
  },

  setProducts({ commit }, products) {
    commit('SET_PRODUCTS', products)
  },

  async exportProducts() {
    try {
      flashMessages.methods.flashSuccess('approval_system.export_begin')
      const response = await ApprovalSystem.exportProducts()

      return Promise.resolve(response)
    } catch (e) {
      flashMessages.methods.flashError(
        'approval_system.bulk_blacklist_not_succesfull'
      )
      return Promise.reject(e)
    }
  },

  setSelectedColumns({ commit }, columns) {
    commit('SET_SELECTED_COLUMNS', columns)
  },

  setPage({ commit }, paging) {
    commit('SET_PAGE', paging)
  },
}
